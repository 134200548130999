import { Form } from "grommet";
import React from "react";
import { useForm } from "react-hook-form";

import { Button } from "src/components/shared/button";
import {
  FormRow, TextInput, FormError
} from "src/components/forms";
import { useLogin } from "src/utils/authentication";

import { PublicLayout } from "src/components/shared/layout/PublicLayout";

import { RouteComponentProps } from "react-router";
import styled from "styled-components";

interface LoginFormValues {
  username: string;
  password: string;
}

interface ResetPasswordFormValues {
  username: string;
  newPassword: string;
  newPasswordConfirm: string;
}

const Login: React.FunctionComponent<RouteComponentProps> = ({ history }) => {
  const login = useLogin();
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const {
    handleSubmit, register, errors, getValues
  } = useForm<LoginFormValues>();

  /**
   * @param data Submit the form
   */
  const handleLogin = React.useCallback(async () => {
    try {
      const values = getValues();

      await login(values.username, values.password);
      history.push("/items");
    } catch (error) {
      setErrorMessage(error.message);
    }
  },
  [
    getValues,
    login,
    history
  ]);

  return <PublicLayout>
    <LogoWrapper>
      <img src="/logo_purple_bg.png" />
    </LogoWrapper>
    {<FormError message={errorMessage}/>}
    <Form onSubmit={handleSubmit(handleLogin)}>
      <FormRow label="Email" error={errors.username?.message}>
        <TextInput name="username" ref={register({ required: "Email address is required" })}/>
      </FormRow>

      <FormRow label="Password" error={errors.password?.message}>
        <TextInput name="password" type="password" ref={register({
          required: "Password is required"
          // pattern: {
          //   value: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d+=^$*.[\]{}()?\-"!@#%&/,><':;|_~` ]{8,}$/),
          //   message: "Password must be at least 8 characters, 1 upper case, 1 lowercase and 1 number"
          // }
        })} />
      </FormRow>

      <Button type="submit" label="Login"/>
    </Form>

  </PublicLayout>;
};

export default Login;

const LogoWrapper = styled.div`
  img {
    display: block;
    width: 200px;
    margin: auto;
  }
`;
