export enum DeviceTypeEnum {
  ANDROID = "ANDROID",
  IOS = "IOS",
  DEFAULT = "DEFAULT"
}

export enum AppTypeEnum {
  PUBLIC = "PUBLIC",
  BUSINESS = "BUSINESS",
  SITE = "SITE"
}

export enum SocialTypeEnum {
  FACEBOOK = "FACEBOOK",
  APPLE = "APPLE",
  REGULAR = "REGULAR",
  DEFAULT = "DEFAULT",
  TWITTER = "TWITTER",
  LINKEDIN = "LINKEDIN",
  GOOGLEPLUS = "GOOGLE_PLUS"
}

export interface ItemCategoryDTO {
  /**
   *
   * @type {string}
   * @memberof ItemCategoryDTO
   */
  category: ItemCategoryEnum;
  /**
   *
   * @type {number}
   * @memberof ItemCategoryDTO
   */
  cost: number;
  /**
   *
   * @type {number}
   * @memberof ItemCategoryDTO
   */
  count: number;
}

export enum ItemCategoryEnum {
  ALL = "ALL",
  ANIMALS = "ANIMALS",
  ART = "ART",
  BIKE = "BIKE",
  CAMERA = "CAMERA",
  CLOTHING = "CLOTHING",
  DECOR = "DECOR",
  DISPLAY = "DISPLAY",
  DRONE = "DRONE",
  GLASSES = "GLASSES",
  HANDHELDCONSOLE = "HANDHELD_CONSOLE",
  INSTRUMENTS = "INSTRUMENTS",
  JEWELLERY = "JEWELLERY",
  LAPTOP = "LAPTOP",
  LUGGAGE = "LUGGAGE",
  PHONE = "PHONE",
  SCOOTER = "SCOOTER",
  SHOES = "SHOES",
  SPORTS = "SPORTS",
  WATCH = "WATCH",
  TOOLS = "TOOLS",
  OTHERS = "OTHERS"
}