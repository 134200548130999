import React from "react";
import { Text } from "src/components/shared/text";
import styled, { css } from "styled-components";

interface FormGroupProps {
  groupLabel?: string;
}

export const FormGroup: React.FC<FormGroupProps> = ({ groupLabel, children }) => {
  return (
    <InfoPanelStyled>
      <StyledLabel as="h4">
        {groupLabel}
      </StyledLabel>
      {children}
    </InfoPanelStyled>
  );
};

const StyledLabel = styled(Text)`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.global?.control?.border?.color};
    margin-bottom: 1rem;
  `};
`;

const InfoPanelStyled = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.global?.control?.border?.color};
    display: flex;
    flex-direction: column;
    background: ${theme.colors.Neutral100};
    border-radius: ${theme.borderRadius.medium};
    padding: 2rem;
    margin-bottom: 2rem;
    width: 100%;
  `};
`;
