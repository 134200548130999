import React from "react";
import styled from "styled-components";

/**
 * Info layout wrapper
 *
 * Usage: InfoLayoutWrapper > InfoLayoutColumn / InfoLayoutColumnLeft / InfoLayoutColumnRight
 */
export const InfoLayoutWrapper: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { children, ...rest } = props;

  return (
    <InfoLayoutWrapperStyled {...rest}>
      {children}
    </InfoLayoutWrapperStyled>
  );
};

const InfoLayoutWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;

  > div:nth-child(1n+2) {
    padding-top: 4.8rem;
  }

  @media (min-width: 768px) {
    flex-direction: row;

    > div {
      padding-top: 0 !important;
    }
  }
`;
