import React from "react";
import { HeaderProps } from "react-table";
import { $enum } from "ts-enum-util";
import styled from "styled-components";

import { Text } from "src/components/shared/text";
import { ItemDTOStatusEnum } from "src/utils/api";
import { Select } from "../forms/Select";
import { mapItemStatus } from "src/utils/item";

export const SelectItemStatusColumnFilter = Object.assign(<T extends Record<string, unknown>>({ 
  column,
  onChange
}: HeaderProps<T> & {
  onChange: (columnId: string, value: ItemDTOStatusEnum | undefined) => void;
}): React.ReactElement => {
  const options: {
    label: string;
    value: ItemDTOStatusEnum | undefined;
}[] = ($enum(ItemDTOStatusEnum).getKeys()).reduce((
  acc: Array<{label: string; value: ItemDTOStatusEnum}>, key, index
) => {
  return acc.concat([
    {
      label: mapItemStatus($enum(ItemDTOStatusEnum).getValues()[index]),
      value: $enum(ItemDTOStatusEnum).getValues()[index]
    }
  ]);
}, []);

  const {
    filterValue, setFilter, id 
  } = column;

  const defaultOption = {
    value: undefined,
    label: "All statuses" 
  };

  options.unshift(defaultOption);

  return (
    <Select
      value={filterValue !== undefined ? options.find(option => option.value === filterValue) : defaultOption}
      name={id}
      placeholder="Select category"
      onChange={({ value }) => {
        setFilter(value ? value.value : undefined);

        if (onChange) {
          onChange(id, value ? value.value : undefined);
        }
      }}
      options={options}
      labelKey="label"
      valueKey="value"
      // eslint-disable-next-line react/no-children-prop
      children={(option=> <StyledOption>{option.label}</StyledOption>)}
    />
  );
}, { displayName: "Select" });

const StyledOption = styled(Text)`
  width: 100%;
  padding: 1rem;
  display: block;
`;
