import styled, { css } from "styled-components";
import React from "react";

export const PublicLayout: React.FC = props => {
  return (
    <Wrapper>
      <PageWrapper>
        <ContentWrapper>
          {props.children}
        </ContentWrapper>
      </PageWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const PageWrapper = styled.main`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 4rem;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.Neutral500}
`;

const ContentWrapper = styled.div`
  ${({ theme }) => css`
    padding: 4rem;
    background: ${theme.colors.Neutral200};
    width: 100%;
    margin: 50px 0;

    @media (min-width: 600px) {
      margin: 50px auto;
      max-width: 100%;
      width: 600px;
      border-radius: ${theme.borderRadius.medium};
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.4);
    }
  `};
`;