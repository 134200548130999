import React from "react";
import styled, { css } from "styled-components";

const TableContent: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { children, ...rest } = props;

  return (
    <TableContentStyled {...rest}>
      {children}
    </TableContentStyled>
  );
};

const TableContentStyled = styled.div`
  ${({ theme }) => css`
    overflow-x: scroll;

    /* The emerging W3C standard that is currently Firefox-only */
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.Neutral500} ${theme.colors.Neutral300};

    &::-webkit-scrollbar {
      width: 1.2rem;
    }
    &::-webkit-scrollbar-track {
      background: ${theme.colors.Neutral300};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.Neutral500};
      border-radius: ${theme.borderRadius.large};
      border: 0.3rem solid ${theme.colors.Neutral300};
    }
  `};
`;

export default TableContent;