import React from "react";
import { TableHeaderProps, TableSortByToggleProps } from "react-table";
import styled, { css, DefaultTheme } from "styled-components";

interface TableHeadCellProps extends React.HTMLAttributes<HTMLDivElement>, TableHeaderProps, TableSortByToggleProps {
  active: boolean;
  canSort: boolean;
  direction: "desc" | "asc";
}

interface StyledTableHeadCellProps {
  active: TableHeadCellProps["active"];
  canSort: boolean;
  direction: TableHeadCellProps["direction"];
}

const TableHeadCell: React.FC<TableHeadCellProps> = props => {
  const {
    active, children, ...rest
  } = props;

  return (
    <StyledTableHeadCell
      active={active}
      {...rest}
    >
      {children}
    </StyledTableHeadCell>

  );
};

export default TableHeadCell;

const handleActiveChevronStyling = (
  theme: DefaultTheme, active: StyledTableHeadCellProps["active"], direction: StyledTableHeadCellProps["direction"]
) => {
  const baseCss = css`
    content: "";
    margin-left: 0.8rem;
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
  `;

  // If the header cell is not active, don't style anything
  if (!active) {
    return null;
  }

  switch (direction) {
    case "asc":
      return css`
        &::after {
          ${baseCss};
          border-top: 1rem solid ${theme.colors.Neutral600};
        }
      `;

    case "desc":
      return css`
        &::after {
          ${baseCss};
          border-bottom: 1rem solid ${theme.colors.Neutral600};
        }
      `;

    default:
      return null;
  }
};

const StyledTableHeadCell = styled.div<StyledTableHeadCellProps>`
  ${({
    theme, active, direction, canSort
  }) => css`
    display: flex;
    align-items: center;
    padding-left: 1%;
    padding-right: 1%;
    font-weight: ${theme.font.weights.medium};
    cursor: ${canSort ? "pointer" : "default"};
    position: relative;
    &:hover {
      .resizer {
        display: inline-block;
      }
    }
    :last-child {
      border-right: 0;
    }
    .resizer {
      display: none;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      touch-action:none;
      padding: 0 10px;
      &:after{
        border-radius: 20px;
        transition: all ease-in-out 0.2s;
        transform: translateX(-50%);
        left: 50%;
        content: "";
        position: absolute;
        background: white;
        width: 3px;
        height: 100%;
      }
      &:hover, &.isResizing {
        display: inline-block;
        &:after{
          width: 6px;
          background: ${theme.colors.Neutral900};
        }  
      }
    }
    ${handleActiveChevronStyling(
    theme, active, direction
  )};
  `};
`;
