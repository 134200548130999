import styled, { css } from "styled-components";
import { TextArea } from "grommet";

export const InputTextArea = styled(TextArea)`
  width: 100%;
  padding: 1rem;
  font-size: 1.6rem;

  ${({ theme }) => css`
    font-size: ${theme.font.sizes.bodyRegular};
    border: 1px solid ${theme.global?.control?.border?.color};
    outline: none;
    border-radius: 4px;
  `
}
`;