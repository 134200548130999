import compact from "lodash/compact";
import React, {
  useState, PropsWithChildren, ReactElement
} from "react";
import {
  Cell,
  Filters,
  Row,
  TableOptions,
  useColumnOrder,
  useFilters,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useResizeColumns,
  SortingRule
} from "react-table";
import styled from "styled-components";

import TableBody from "src/components/shared/table/TableBody";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { Text } from "../text";
import { LoadingSpinner } from "src/components/shared/loading-spinner";

import { ColumnHidePage } from "./ColumnHidePage";
import { DefaultColumnFilter, DefaultHeader } from "./DefaultHeaderFilter";
import { FeaturedFilter } from "./FeaturedFilter";
import bulkSelection from "./BulkSelection";
import { TableFilters } from "./TableFilters";
import TableFooter from "./TableFooter";
import TableHeaderRow from "./TableHeaderRow";
import TableHeadCell from "./TableHeadCell";
import { TablePagination } from "./TablePagination";
import TableToolBar from "./TableToolBar";
import TableWrapper from "./TableWrapper";
import { TooltipCell } from "./TooltipCell";
import TableContent from "./TableContent";
import TableBodyCell from "./TableBodyCell";
import TableBodyRow from "./TableBodyRow";

export interface Table<T extends Record<string, unknown> = Record<string, unknown>> extends TableOptions<T> {
  name: string;
  title?: string;
  onClick?: (row: Row<T>) => void;
  fetchData?: ({
    pageIndex, pageSize, tableFilters, sortBy
  }: {pageIndex: number; pageSize: number; tableFilters?: Filters<T>; sortBy?: Array<SortingRule<keyof T>>}) => void;
  loading: boolean;
  featuredFilter?: keyof T;
  handleSelectRows?: (selectedRows: T[]) => void;
  defaultHiddenColumns?: string[];
  initalSortBy?: Array<SortingRule<keyof T>>;
  useBulkSelect?: boolean;
  usePagination?: boolean;
  useHideColumns?: boolean;
  useSortBy?: boolean;
  useResizeColumns?: boolean;
  useFilters?: boolean;
  noFoundMessage?: string;
}

const defaultColumn = {
  Filter: DefaultColumnFilter,
  Cell: TooltipCell,
  Header: DefaultHeader,
  minWidth: 30, // minWidth is only used as a limit for resizing
  width: 150, // width is used for both the flex-basis and flex-grow
  maxWidth: 300 // maxWidth is only used as a limit for resizing
};

export function Table<T extends Record<string, unknown>>(props: PropsWithChildren<Table<T>>): ReactElement {
  const {
    name, columns, onClick, fetchData, data, pageCount: controlledPageCount, loading, handleSelectRows, defaultHiddenColumns, initalSortBy
  } = props;

  const [initialState, setInitialState] = useLocalStorage<Record<string, unknown>>(`tableState:${name}`, {});
  const [tableFilters, setTableFilters] = useState<Filters<T>>([]);

  const hooks = compact([
    useColumnOrder,
    props.useFilters && useFilters,
    props.useSortBy && useSortBy,
    useFlexLayout,
    usePagination,
    props.useBulkSelect && useRowSelect,
    props.useBulkSelect && bulkSelection,
    props.useResizeColumns && useResizeColumns
  ]);

  const instance = useTable<T>({
    ...props,
    columns,
    autoResetPage: true,
    defaultColumn,
    initialState: {
      hiddenColumns: defaultHiddenColumns || [],
      ...initialState,
      pageIndex: 0,
      sortBy: initalSortBy || []
    },
    manualPagination: true,
    manualFilters: true,
    manualSortBy: true,
    pageCount: controlledPageCount
  },
  ...hooks);

  const {
    getTableProps, getTableBodyProps, page, prepareRow, state, headers, selectedFlatRows
  } = instance;

  const {
    pageIndex,
    pageSize,
    sortBy,
    hiddenColumns
  } = state;

  // Handle data fetching
  React.useEffect(() => {
    if (fetchData) {
      fetchData({
        tableFilters,
        pageIndex,
        pageSize,
        sortBy
      });
    }
  }, [
    pageIndex,
    sortBy,
    pageSize,
    fetchData,
    tableFilters
  ]);

  // Handle row select
  React.useEffect(() => {
    if (handleSelectRows && selectedFlatRows) {
      handleSelectRows(selectedFlatRows.map(d => d.original));
    }
  }, [handleSelectRows, selectedFlatRows]);

  // Handle saving table state to local storage
  React.useEffect(() => {
    const val = {
      sortBy,
      pageSize,
      hiddenColumns
    };

    setInitialState(val);
  }, [
    setInitialState,
    sortBy,
    pageSize,
    hiddenColumns
  ]);

  const cellClickHandler = (cell: Cell<T>) => () => {
    onClick && cell.column.id !== "_selector" && onClick(cell.row);
  };

  return (
    <TableWrapper disabled={loading}>
      <TableToolBar>
        <Text as="h4" style={{ marginRight: "auto" }}>
          {props.title}
        </Text>
        {(props.useFilters && props.featuredFilter) && (
          <FeaturedFilter<T> instance={instance} featuredFilter={props.featuredFilter} setTableFilters={data => setTableFilters(data)} />
        )}
        {props.useHideColumns && (
          <ColumnHidePage<T> instance={instance} />
        )}
        {props.useFilters && (
          <TableFilters<T> instance={instance} setTableFilters={data => setTableFilters(data)} />
        )}
      </TableToolBar>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {!data || data.length === 0 ? (
            <StyledNoData as="p">
              {props.noFoundMessage || "No data found."}
            </StyledNoData>
          ) : (
            <>
              <TableContent>
                <div {...getTableProps()}>
                  <TableHeaderRow>
                    {headers.map(column => {
                      return column.isVisible && (
                        <TableHeadCell
                          active={column.isSorted}
                          canSort={column.canSort}
                          direction={column.isSortedDesc ? "desc" : "asc"}
                          
                          {...column.getHeaderProps()}
                          key={column.id}
                        >
                          <div 
                            {...props.useSortBy && column.getSortByToggleProps()}
                          >
                            {column.render("Header")}
                          </div>
                          {column.canResize && ( 
                            <div
                              {...column.getResizerProps()}
                              className={`resizer ${
                                column.isResizing ? "isResizing" : ""
                              }`}
                            />
                          )}
                        </TableHeadCell>
                      );
                    })}
                  </TableHeaderRow>
                  <TableBody {...getTableBodyProps()}>
                    {page.map(row => {
                      prepareRow(row);

                      return (
                        <TableBodyRow {...row.getRowProps()} key={row.id}>
                          {row.cells.map(cell => {
                            return (
                              <TableBodyCell
                                {...cell.getCellProps()}
                                onClick={cellClickHandler(cell)}
                                key={cell.column.id}
                              >
                                {cell.value !== null ? cell.render("Cell") : "-"}
                              </TableBodyCell>
                            );
                          })}
                        </TableBodyRow>
                      );
                    })}
                  </TableBody>
                </div>
              </TableContent>
              <TableFooter>
                {props.usePagination && (
                  <TablePagination<T> instance={instance} />
                )}
              </TableFooter>
            </>
          )}
        </>
      )}
    </TableWrapper>
  );
}

const StyledNoData = styled(Text)`
  padding-top: 8vh;
  padding-bottom: 8vh;
  text-align: center;
`;