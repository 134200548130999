import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "src/components/shared/button";
import {
  Select, FormError, FormRow, TextInput, FormGroup, DateInput
} from "src/components/forms";
import { Box } from "grommet";
import { $enum } from "ts-enum-util";
import { UserOriginEnum } from "src/utils/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import styled from "styled-components";
import formatDate from "date-fns/format";
import parse from "date-fns/parse";
import isValid from "date-fns/isValid";

export interface PublicUserFormInput {
  email: string;
  firstName: string;
  lastName: string;
  origin: UserOriginEnum;
  originInfo: string;
  phoneNumber?: string;
  posBusinessPartnerUUID?: string;
  birthdate?: string | null;
  locationAddress1?: string;
  locationAddress2?: string;
  locationCity?: string;
  locationPostcode?: string;
}
interface PublicUserFormProps {
  isLoading: boolean;
  defaultValues?: Partial<PublicUserFormInput>;
  onSubmit: (data: PublicUserFormInput) => void;
  type: "create" | "update";
}

const schema = yup.object().shape({
  email: yup
    .string()
    .transform((value: string) => value === "" ? undefined : value)
    // eslint-disable-next-line no-useless-escape
    .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Invalid email format").required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  origin: yup.string().required(),
  originInfo: yup.string().optional(),
  posBusinessPartnerUUID:  yup
    .string()
    .when("origin", {
      is: UserOriginEnum.POS,
      then: yup.string().required("POS Business Partner UUID is a required field")
    }),
  phoneNumber: yup
    .string()
    .transform((value: string) => value === "" ? undefined : value)
    .matches(/(\+44|0)7\d{9}/, "Invalid mobile number")
    .optional(),
  birthdate: yup.string().nullable().matches(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/, "Invalid birth date")
    .test(
      "Valid birth date",
      "Invalid birth date",
      value => value ? isValid(parse(
        value, "dd/MM/yyyy", new Date()
      )) : true  
    ).optional(),
  locationAddress1: yup.string().optional(),
  locationAddress2: yup.string().optional(),
  locationCity: yup.string().optional(),
  locationPostcode: yup.string().optional()
});

/**
 * public user form
 */
export const PublicUserForm: React.FC<PublicUserFormProps> = ({
  defaultValues, onSubmit, isLoading, type
}) => {
  const [birthDate, setBirthDate] = React.useState<Date | null>(defaultValues && defaultValues.birthdate ? parse(
    defaultValues.birthdate, "dd/MM/yyyy", new Date()
  ) : null);

  if (type === "update" && !defaultValues?.origin) {
    (defaultValues as Partial<PublicUserFormInput>).origin = UserOriginEnum.DEFAULT;
  }

  const {
    register, handleSubmit, errors, setValue, watch
  } = useForm<PublicUserFormInput>({
    defaultValues: { ...defaultValues } as PublicUserFormInput,
    resolver: yupResolver(schema)
  });

  const onFormSubmit = React.useCallback((user: PublicUserFormInput) => {
    onSubmit({ ...user });
  }, [onSubmit]);

  // On mount
  React.useEffect(() => {
    register({ name: "origin" });
  }, [register]);

  React.useEffect(() => {
    if (defaultValues && defaultValues.birthdate) {
      setValue("birthdate", defaultValues.birthdate);
    }
  }, [setValue, defaultValues]);

  // Exclude DEFAULT as origin option for creation form
  // since it represents an user standard sign up.
  let originValues = $enum(UserOriginEnum).getValues();

  originValues = originValues.filter(value => type === "update" || (type === "create" && value !== UserOriginEnum.DEFAULT));

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <FormGroup groupLabel="User details">
        <DetailsRow>
          <FormRow label="First Name">
            <TextInput
              name="firstName"
              ref={register}
            />
            {<FormError message={errors.firstName?.message} />}
          </FormRow>

          <FormRow label="Last Name">
            <TextInput
              name="lastName"
              ref={register}
            />
            {<FormError message={errors.lastName?.message} />}
          </FormRow>

        </DetailsRow>
        <DetailsRow>
          <FormRow label="Email">
            <TextInput
              name="email"
              ref={register}
            />
            {<FormError message={errors.email?.message} />}
          </FormRow>
        </DetailsRow>
        <DetailsRow>
          <FormRow label="Birth date">
            <DateInput
              maxDate={new Date()}
              dateFormat="dd/MM/yyyy"
              name="birthdate"
              selected={birthDate ? birthDate : null}
              onChange={(value: Date) => {
                if (value) {
                  setValue("birthdate", formatDate(new Date(value), "dd/MM/yyyy"));
                  setBirthDate(value);
                } else {
                  setValue("birthdate", null);
                  setBirthDate(null);
                }
              }}
            />
            {<FormError message={errors.birthdate?.message} />}
          </FormRow>
        </DetailsRow>
        <DetailsRow>
          <FormRow label="Phone number">
            <TextInput
              name="phoneNumber"
              ref={register}
            />
            {<FormError message={errors.phoneNumber?.message} />}
          </FormRow>
        </DetailsRow>
      </FormGroup>

      <FormGroup groupLabel="User address">
        <DetailsRow>
          <FormRow label="Address Line 1">
            <TextInput
              name="locationAddress1"
              ref={register}
            />
            {<FormError message={errors.locationAddress1?.message} />}
          </FormRow>
        </DetailsRow>
        <DetailsRow>
          <FormRow label="Address Line 2">
            <TextInput
              name="locationAddress2"
              ref={register}
            />
            {<FormError message={errors.locationAddress2?.message} />}
          </FormRow>
        </DetailsRow>
        
        <DetailsRow>
          <FormRow label="City">
            <TextInput
              name="locationCity"
              ref={register}
            />
            {<FormError message={errors.locationCity?.message} />}
          </FormRow>
          <FormRow label="Postcode">
            <TextInput
              name="locationPostcode"
              ref={register}
            />
            {<FormError message={errors.locationPostcode?.message} />}
          </FormRow>
        </DetailsRow>
        
      </FormGroup>
      <FormGroup groupLabel="Origin information">
        <DetailsRow>
          <FormRow label="Origin">
            <Select
              options={originValues}
              value={watch("origin")}
              onChange={e => {
                setValue(
                  "origin", e.option, { shouldValidate: true }
                );
              }}
            />
            {<FormError message={errors.origin?.message} />}
          </FormRow>

          <FormRow label="Origin Information">
            <TextInput
              name="originInfo"
              ref={register}
            />
            {<FormError message={errors.originInfo?.message} />}
          </FormRow>

        </DetailsRow>

        {watch("origin") === UserOriginEnum.POS &&
      <DetailsRow>
        <FormRow label="POS Business Partner UUID">
          <TextInput
            name="posBusinessPartnerUUID"
            ref={register({
              required: {
                value: true,
                message: "This field is required"
              }
            })}
          />
          {<FormError message={errors.posBusinessPartnerUUID?.message} />}
        </FormRow>

      </DetailsRow>
        }
      </FormGroup>

      <Box margin={{ top: "2rem" }}>
        <Button
          type="submit"
          label="Submit"
          disabled={isLoading}
        />

      </Box>
    </form>
  );
};

const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 70rem;

  > div:nth-child(1n+2) {
    margin-left: 1rem;
  }
`;
