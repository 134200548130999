import { DefaultTheme } from "styled-components";

enum BreakPoints {
  xs = "0",
  sm = "+300",
  md = "+501",
  lg = "+750",
  xl = "+960"
}

enum BorderRadius {
  small = "0.5rem",
  medium = "1rem",
  large = "1.5rem"
}

enum ColorPalette {
  // Neutral - Greys
  Neutral100 = "#FFFFFF",
  Neutral200 = "#E8E9EF",
  Neutral300 = "#909090",
  Neutral400 = "#525055",
  // unconfirmed
  Neutral500 = "#E4E8EE",
  Neutral600 = "#A6B4C8",
  Neutral700 = "#808FA3",
  Neutral800 = "#5F6F84",
  // end unconfirmed
  Neutral900 = "#302D36",
  // Primary
  Primary500 = "#691EBF",
  // Accent - Green
  AccentGreen500 = "#42E5A6",
  // Accent - Red
  AccentRed500 = "#D3140F",
  // Accent - Amber
  AccentAmber500 = "#F2911F",
}

export const theme: DefaultTheme = {
  actions: {
    error: ColorPalette.AccentRed500,
    success: ColorPalette.AccentGreen500
  },
  breakpoints: BreakPoints,
  colors: {
    Neutral100: ColorPalette.Neutral100,
    Neutral200: ColorPalette.Neutral200,
    Neutral300: ColorPalette.Neutral300,
    Neutral400: ColorPalette.Neutral400,
    Neutral500: ColorPalette.Neutral500,
    Neutral600: ColorPalette.Neutral600,
    Neutral700: ColorPalette.Neutral700,
    Neutral800: ColorPalette.Neutral800,
    Neutral900: ColorPalette.Neutral900,
    // Primary
    Primary500: ColorPalette.Primary500,
    // Accent - Green
    AccentGreen500: ColorPalette.AccentGreen500,
    // Accent - Red
    AccentRed500: ColorPalette.AccentRed500,
    // Accent - Amber
    AccentAmber500: ColorPalette.AccentAmber500
  },
  font: {
    family: {
      primary: "'Cabin', sans-serif",
      secondary: "'Cabin', sans-serif"
    },
    sizes: {
      heading1: "3.6rem",
      heading2: "2.8rem",
      heading3: "2.6rem",
      heading4: "2rem",
      heading5: "1.8rem",
      heading6: "1.4rem",
      bodyRegular: "1.8rem",
      bodySmall: "1.6rem",
      small: "1.2rem"
    },
    weights: {
      light: 300,
      regular: 400,
      medium: 500
    }
  },
  borderRadius: {
    small: BorderRadius.small,
    medium: BorderRadius.medium,
    large: BorderRadius.large
  },
  checkBox: { color: ColorPalette.Primary500 },
  global: {
    // changes here will affect more than one component at a time
    colors: {
      header: "#2e3447",
      adminBackground: "#f7f7f7",
      primaryText: "#495664",
      control: ColorPalette.Neutral100
    },
    control: {
      border: {
        color: "#495664",
        width: "1px"
      }
    }
  }
};
