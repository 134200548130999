import React, { ReactElement, FormEvent } from "react";
import { TableInstance, Filters } from "react-table";
import { Box, Drop } from "grommet";
import { Filter } from "grommet-icons";
import styled from "styled-components";

import { Text } from "../text";
import { Button } from "../button";
import { FormRow } from "../../forms/FormRow";

type TableFilters<T extends Record<string, unknown>> = {
  instance: TableInstance<T>;
  setTableFilters: (data: Filters<T>) => void;
};

export function TableFilters<T extends Record<string, unknown>>({ instance, setTableFilters }: TableFilters<T>): ReactElement {
  const { allColumns, setAllFilters } = instance;

  const onSubmit = React.useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const filterData: Filters<T> = [];
    
    allColumns.map(col => {
      filterData.push({
        id: col.id,
        value: col.filterValue
      });
    });
    setTableFilters(filterData);
  },
  [allColumns, setTableFilters]);

  const resetFilters = React.useCallback(() => {
    setAllFilters([]);
    setTableFilters([]);
  }, [setAllFilters, setTableFilters]);

  const targetRef = React.useRef<HTMLDivElement>(null);
  const [showDrop, setShowDrop] = React.useState(false);

  return (
    <Box align="center" justify="center">
      <Box
        pad="small"
        align="center"
        justify="start"
        ref={targetRef}
        onClick={() => setShowDrop(!showDrop)}
      >
        <Filter />
      </Box>
      {(showDrop && targetRef.current) && (
        <Drop
          onClickOutside={() => setShowDrop(false)}
          align={{
            top: "bottom",
            right: "right" 
          }}
          target={targetRef.current}
        >
          <Box>
            <Box 
              pad="small" 
              align="center"
              direction="row"
              justify="between"
              style={{ minWidth: "360px" }}
            >
              <Text as="small" bold>
                Advanced Filters
              </Text>
              <Button backgroundColor="Neutral700" onClick={resetFilters} label="Reset" />
            </Box>

            <Box 
              pad="small" 
            >
              <FormStyled onSubmit={onSubmit}>
                {allColumns
                  .filter(it => it.canFilter)
                  .map(column => (
                    <FormRow key={column.id} label={column.render("Header")}>
                      {column.render("Filter")}
                    </FormRow>
                  ))}
                <Button primary type="submit" label="Submit" />
              </FormStyled>
            </Box>
          </Box>
        </Drop>
      )}
    </Box>

  );
}

const FormStyled = styled.form`
  .featured-filter-submit-btn {
    display: none;
  }
`;