import React from "react";
import { toast } from "react-toastify";
import { Box } from "grommet";
import { PageHeader } from "src/components/shared/layout";
import { Layout } from "src/components/shared/layout/Layout";

import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { InsurerForm, InsurerFormInput } from "src/components/insurers/InsurerForm";

interface NewInsurerPageParams {
  insurerID: string;
}

export const NewInsurerPage: React.FC<PageProps<{}, NewInsurerPageParams>> = props => {
  const insurerID = React.useMemo(() => props.match?.params.insurerID || "", [props.match]);
  const [updateInsurerResponse, updateInsurerRequest] = useApiRequest("INSURERS:createInsurer");

  // On form submission
  const onSubmit = (insurer: InsurerFormInput) => {
    updateInsurerRequest({
      pathParams: { insurerID },
      data: {
        providerName: insurer.providerName,
        enabled: insurer.enabled,
        base64LogoUrl: Buffer.from(insurer.logoUrl, "utf-8").toString("base64"),
        base64RedirectUrl: Buffer.from(insurer.redirectUrl, "utf-8").toString("base64"),
        base64CallbackUrl: Buffer.from(insurer.callbackUrl, "utf-8").toString("base64"),
        title: insurer.title,
        insuranceDetails: insurer.insuranceDetails,
        smallLetter: insurer.smallLetter,
        successTitle: insurer.successTitle,
        successDetails: insurer.successDetails
      }
    });
  };

  React.useEffect(() => {
    if (updateInsurerResponse.data) {
      toast.success("Successfully created insurance provider!");
      props.history.push("/insurers");
    }

    if (updateInsurerResponse.errorMessage) {
      toast.error(updateInsurerResponse.errorMessage);
    }
  }, [
    updateInsurerResponse.data,
    updateInsurerResponse.errorMessage,
    props.history
  ]);

  return (
    <Layout>
      <PageHeader title="Create insurance provider" backLink={() => props.history.goBack()} />
      <Box>
        <InsurerForm
          isLoading={updateInsurerResponse.loading}
          onSubmit={onSubmit}
        />
      </Box>
    </Layout>
  );
};
