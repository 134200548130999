import React from "react";
import { useForm } from "react-hook-form";
import { Close } from "grommet-icons";

import { Button } from "src/components/shared/button";
import {
  Select,
  FormError,
  FormRow,
  TextInput,
  InputTextArea,
  FormGroup,
  DateInput,
  CheckBoxInput
} from "src/components/forms";
import { $enum } from "ts-enum-util";
import {
  ItemCategoryEnum,
  ItemOriginEnum,
  UserDTO,
  InsurerDTO
} from "src/utils/api";
import styled from "styled-components";
import useApiRequest from "src/utils/api/useApiRequest";
import { useCombobox } from "downshift";
import { useDebounce } from "../../hooks/useDebounce";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { theme } from "src/utils/theme";
import { toast } from "react-toastify";

export interface ItemFormInput {
  category: ItemCategoryEnum;
  brand?: string;
  model?: string;
  color?: string;
  origin: ItemOriginEnum;
  originInfo?: string;
  description?: string;
  posBusinessPartnerUUID?: string;
  userUUID: string;
  isInsured: boolean;
  insuranceProvider?: string;
  insurancePolicyNumber?: string;
  insuranceStartedAt?: Date;
  insuranceQuoteId? : string;
}
interface ItemFormProps {
  isLoading: boolean;
  defaultValues?: Partial<ItemFormInput> & { user?: UserDTO | null };
  onSubmit: (data: ItemFormInput, userUUID: string) => void;
  type: "create" | "update";
}

const schema = yup.object().shape({
  category: yup.string().required(),
  brand: yup.string().optional(),
  model: yup.string().optional(),
  color: yup.string().optional(),
  origin: yup.string().required(),
  originInfo: yup.string().optional(),
  description: yup.string().optional(),
  posBusinessPartnerUUID: yup
    .string()
    .when("origin", {
      is: ItemOriginEnum.POS,
      then: yup.string().required("POS Business Partner UUID is a required field")
    }),
  isInsured: yup.boolean().required(),
  insuranceProvider: yup.string().when("isInsured", {
    is: true,
    then: yup.string().required()
  }),
  insuranceStartedAt: yup.date().when("isInsured", {
    is: true,
    then: yup.date().required()
  }),
  insurancePolicyNumber: yup.string().when("isInsured", {
    is: true,
    then: yup.string().optional()
  }),
  insuranceQuoteId: yup.string().when("isInsured", {
    is: true,
    then: yup.string().optional()
  })
});

/**
 * Item form
 */
export const ItemForm: React.FC<ItemFormProps> = ({
  defaultValues, onSubmit, isLoading, type
}) => {
  if (!defaultValues) {
    defaultValues = {};
  }

  if (type === "update" && !defaultValues?.origin) {
    (defaultValues as Partial<ItemFormInput>).origin = ItemOriginEnum.DEFAULT;
  }

  (defaultValues as Partial<ItemFormInput>).isInsured = !!(defaultValues && defaultValues.insuranceProvider);

  const [getAllInsurersResponse, getAllInsurersRequest] = useApiRequest("INSURERS:getAllInsurers");
  const [getUsersResponse, getUsersRequest] = useApiRequest("USERS:getAllPublicUsers");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [insurers, setInsurers] = React.useState<InsurerDTO[]>([]);
  const [userUUID, setUserUuid] = React.useState<string | null>(defaultValues && defaultValues.userUUID ? defaultValues.userUUID : null);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const [insuranceStartedAt, setinsuranceStartedAt] = React.useState<Date | null>(defaultValues && defaultValues.insuranceStartedAt ? new Date(defaultValues.insuranceStartedAt) : null);

  React.useEffect(()=> {
    getAllInsurersRequest({});
  }, [getAllInsurersRequest]);

  const userItems = React.useMemo(() => {
    return getUsersResponse.data?.items || [];
  }, [getUsersResponse.data]);

  const onSearch = React.useCallback((searchTerm: string) => {
    if (searchTerm.length) {
      getUsersRequest({
        params: {
          page: 1,
          pageSize: 20,
          query: `emailAddress~${searchTerm}`
        }
      });
    }
  }, [getUsersRequest]);

  const {
    reset,
    isOpen,
    selectedItem,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    setInputValue
  } = useCombobox({
    initialSelectedItem: defaultValues?.user ? defaultValues?.user :
      defaultValues?.userUUID ? { id: defaultValues.userUUID } : undefined,
    items: userItems,
    onInputValueChange: ({ inputValue }) => {
      if (inputValue) {
        setSearchTerm(inputValue);
      }
    },
    onStateChange: changes => {
      if (changes.selectedItem && changes.selectedItem.id) {
        setUserUuid(changes.selectedItem.id);

        setValue(
          "userUUID", changes.selectedItem.id, { shouldValidate: true }
        );
        setInputValue("");
      } else {
        setUserUuid(null);
      }
    },
    itemToString: () => ""
  });

  const {
    register, handleSubmit, errors, setValue, watch, setError
  } = useForm<ItemFormInput>({
    defaultValues: { ...defaultValues } as ItemFormInput,
    resolver: yupResolver(schema)
  });

  const onFormSubmit = React.useCallback((item: ItemFormInput) => {
    if (userUUID) {
      onSubmit(item as ItemFormInput, userUUID);
    } else {
      setError("userUUID", {
        message: "Please select a user",
        type: "required"
      });
    }
  }, [
    onSubmit,
    userUUID,
    setError
  ]);

  React.useEffect(() => {
    onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSearch]);

  // On mount
  React.useEffect(() => {
    register({ name: "insuranceStartedAt" });
    register({ name: "insuranceProvider" });
    register({ name: "category" });
    register({ name: "origin" });
  }, [register]);

  const closeSelectedItem = () => {
    reset();
    setUserUuid(null);
  };

  React.useEffect(() => {
    if (getAllInsurersResponse.data) {
      setInsurers(getAllInsurersResponse.data);
    }

    if (getAllInsurersResponse.errorMessage) {
      toast.error(getAllInsurersResponse.errorMessage);
    }
  }, [getAllInsurersResponse]);

  // Exclude DEFAULT as origin option for creation form
  // since it represents an user standard sign up.
  let originValues = $enum(ItemOriginEnum).getValues();

  originValues = originValues.filter(value => type === "update" || (type === "create" && value !== ItemOriginEnum.DEFAULT));

  const insurersOptions = insurers.map(insurer =>({
    label: insurer.providerName,
    value: insurer.ID 
  }));

  const insuranceProvider = watch("insuranceProvider");
  const selectedInsuranceProvider = insurersOptions.find(insurersOption => insurersOption.value === insuranceProvider);

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <FormGroup groupLabel="Item overview">
        <DetailsRow>
          <FormRow label="Category">
            <Select
              name="category"
              options={$enum(ItemCategoryEnum).getValues()}
              value={watch("category")}
              onChange={e => setValue(
                "category", e.option, { shouldValidate: true }
              )}
            />
            {<FormError message={errors.category?.message} />}
          </FormRow>
        </DetailsRow>
        <DetailsRow>
          <FormRow label="Brand">
            <TextInput
              name="brand"
              ref={register()}
            />
            {<FormError message={errors.brand?.message} />}
          </FormRow>
          <FormRow className="model-row" label="Model">
            <TextInput
              name="model"
              ref={register()}
            />
            {<FormError message={errors.model?.message} />}
          </FormRow>
          <FormRow label="Colour">
            <TextInput
              name="color"
              ref={register()}
            />
            {<FormError message={errors.color?.message} />}
          </FormRow>
        </DetailsRow>
      </FormGroup>

      <FormGroup groupLabel="Item details">
        <DetailsRow>
          <FormRow label="User">
            <div
              {...getComboboxProps({}, { suppressRefError: true })}
            >
              <TextInput
                name="userUUID" 
                {...getInputProps({}, { suppressRefError: true })}
                placeholder="Enter email address"
                onFocus={() => {
                  setInputValue("");
                  setUserUuid(null);
                }}
              />

              {!isOpen && selectedItem &&
              <SelectedItemWrapper>
                <TextInput
                  name="userUUID-final"
                  disabled={true}
                  value={selectedItem.emailAddress ? `${selectedItem.emailAddress} - (${selectedItem.id})` : selectedItem.id}
                  style={{
                    background: theme.colors.Primary500,
                    color: theme.colors.Neutral100
                  }}/>
                <Button plain={true} label="" icon={<Close />} onClick={closeSelectedItem} />
              </SelectedItemWrapper>
              }
              {<FormError message={errors.userUUID?.message} />}
            </div>
            
            <div {...getMenuProps({}, { suppressRefError: true })}>
              {isOpen &&
              <UserList> 
                {userItems.map((item, index) => (
                  <li
                    key={`user-${item.id}${index}`}
                    style={{
                      padding: "1rem",
                      backgroundColor:
                            highlightedIndex === index ? "lightgray" : "white",
                      fontWeight: selectedItem === item ? "bold" : "normal"
                    }}
                    {...getItemProps({
                      item,
                      index
                    })}
                  >
                    {`${item.emailAddress} (${item.id} - ${item.type})`}
                  </li>
                ))}
              </UserList>
              }
            </div>
          </FormRow>
        </DetailsRow>

        <DetailsRow>
          <FormRow label="Origin">
            <Select
              size="medium"
              options={originValues}
              value={watch("origin")}
              onChange={e => {
                setValue(
                  "origin", e.option, { shouldValidate: true }
                );
              }}
            />
            {<FormError message={errors.origin?.message} />}
          </FormRow>

          <FormRow label="Origin Information">
            <TextInput
              name="originInfo"
              ref={register()}
            />
            {<FormError message={errors.originInfo?.message} />}
          </FormRow>
        </DetailsRow>

        {watch("origin") === ItemOriginEnum.POS &&
          <DetailsRow>
            <FormRow label="POS Business Partner UUID">
              <TextInput
                name="posBusinessPartnerUUID"
                ref={register()}
              />
              {<FormError message={errors.posBusinessPartnerUUID?.message} />}
            </FormRow>
          </DetailsRow>
        }
        <DetailsRow>
          <FormRow label="Description">
            <InputTextArea
              name="description"
              ref={register()}
            />
            {<FormError message={errors.description?.message} />}
          </FormRow>
        </DetailsRow>
      </FormGroup>
      <FormGroup groupLabel="Insurance details">
        <DetailsRow>
          <FormRow style={{ marginTop: "1rem" }}>
            <CheckBoxInput
              name="isInsured"
              ref={register()}
              label={"Is insured?"}
              onChange={() => { 
                setValue("isInsured", watch("isInsured")); 
              }}
              checked={watch("isInsured")} />
          </FormRow>
        </DetailsRow>
        {watch("isInsured") && 
        <>
          <DetailsRow>
            <FormRow label="Provider">
              <Select
                name="insuranceProvider"
                size="medium"
                options={insurersOptions}
                labelKey="label"
                valueKey="value"
                value={selectedInsuranceProvider}
                onChange={e => {
                  setValue(
                    "insuranceProvider", e.option.value, { shouldValidate: true }
                  );
                }}
              />
              {<FormError message={errors.insuranceProvider?.message} />}
            </FormRow>
            
            <FormRow required label="Insurance started at">
              <DateInput
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                name="insuranceStartedAt"
                selected={insuranceStartedAt ? insuranceStartedAt : null}
                onChange={(value: Date) => {
                  if (value) {
                    setValue("insuranceStartedAt", value);
                    setinsuranceStartedAt(value);
                  }
                }}
              />
              <FormError message={errors.insuranceStartedAt?.message} />
            </FormRow>
          </DetailsRow>
          <DetailsRow>
            <FormRow label="Policy number">
              <TextInput
                name="insurancePolicyNumber"
                ref={register()}
              />
              {<FormError message={errors.insurancePolicyNumber?.message} />}
            </FormRow>
            <FormRow label="Quote id">
              <TextInput
                name="insuranceQuoteId"
                ref={register()}
              />
              {<FormError message={errors.insuranceQuoteId?.message} />}
            </FormRow>
          </DetailsRow>
        </>
        }
      </FormGroup>
      <Button
        type="submit"
        label="Submit"
        disabled={isLoading}
      />
    </form>
  );
};

const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 70rem;

  > div:nth-child(1n+2) {
    margin-left: 1rem;
  }
`;

const UserList = styled.ul`
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: ${theme.colors.Neutral600};
  padding: 0rem 1rem;
  border-radius: ${theme.borderRadius.medium};

  li {
    cursor: pointer;
    border-top: 1px solid ${theme.colors.Neutral600};
  }
`;

const SelectedItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  button {
    margin-left: 1rem;
  }
`;