import React from "react";
import styled, { css } from "styled-components";

const TableHeaderRow: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { children, ...rest } = props;

  return (
    <StyledTableHeaderRow {...rest}>
      {children}
    </StyledTableHeaderRow>
  );
};

const StyledTableHeaderRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    background: ${theme.colors.Neutral300};
    border-radius: ${theme.borderRadius.small};
  `};
`;

export default TableHeaderRow;