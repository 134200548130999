import * as React from "react";
import { Redirect, RouteProps } from "react-router";
import { Route } from "react-router-dom";

import { useAuthenticationContext } from "src/utils/authentication/Authentication";

import { PublicLayout } from "../layout/PublicLayout";
import { LoadingSpinner } from "src/components/shared/loading-spinner";
import { RoleEnum } from "src/utils/api";
import { toast } from "react-toastify";

const PrivateRoute: React.FC<RouteProps> = props => {
  const { authTokenState, authenticating } = useAuthenticationContext();

  const userHasAccess = React.useCallback(() => {
    if (!authTokenState) {
      return null;
    }

    if (authTokenState.role === RoleEnum.FOUNDSTAFFMANAGEMENT) {
      return true;
    } else {
      toast.error("You do not have the correct user role to access this site");

      return false;
    }
  }, [authTokenState]);

  if (authenticating) {
    return <PublicLayout><LoadingSpinner /></PublicLayout>;
  } else if (userHasAccess()) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/" />;
  }
};

export default PrivateRoute;