import React from "react";
import { toast } from "react-toastify";
import { Box } from "grommet";
import { PageHeader } from "src/components/shared/layout";
import { Layout } from "src/components/shared/layout/Layout";

import { PageProps } from "src/pages/Router";
import {
  useApiRequest, EditItemInsuranceRequest, CreateItemInsuranceRequest 
} from "src/utils/api";
import { ItemForm, ItemFormInput } from "src/components/items/ItemForm";
import { ItemInsuranceType, UserDTO } from "src/utils/api";
import { LoadingSpinner } from "src/components/shared/loading-spinner";

interface EditItemPageParams {
  itemUuid: string;
}

type EditItemDefaultValues = Partial<ItemFormInput> & { user?: UserDTO | null };
type ItemInsuranceAction = "create" | "update" | "remove" | "noAction";

const inferItemInsuranceAction = (updatedItem: ItemFormInput, defaultValues: EditItemDefaultValues): ItemInsuranceAction => {
  let action: ItemInsuranceAction = "noAction";

  // The item wasn't insured and it isn't insured after the changes.
  if (!updatedItem.isInsured && !defaultValues.isInsured) {
    action = "noAction";
    // The item wasn't insured and it is insured after the changes.
  } else if (!defaultValues.isInsured && updatedItem.isInsured) {
    action = "create";
    // The item was insured and it is insured after the changes.
  } else if (updatedItem.isInsured && defaultValues.isInsured) {
    // Check if changes have been made.
    const insuranceFields: Array<"insuranceProvider" |"insurancePolicyNumber"| "insuranceStartedAt"| "insuranceQuoteId"> = [
      "insuranceProvider",
      "insurancePolicyNumber",
      "insuranceStartedAt",
      "insuranceQuoteId"
    ];

    const changed = insuranceFields.some(insuranceField => updatedItem[insuranceField] !== defaultValues[insuranceField]);

    if (changed) {
      action = "update";
    } else {
      action = "noAction";
    }
    // The item was insured and it isn't insured after the changes.
  } else if (!updatedItem.isInsured && defaultValues.isInsured) {
    action = "remove";
  }

  return action;
};

export const EditItemPage: React.FC<PageProps<{}, EditItemPageParams>> = props => {
  const itemUuid = React.useMemo(() => props.match?.params.itemUuid || "", [props.match]);
  const [updateItemResponse, updateItemReq] = useApiRequest("ITEMS:adminUpdateItem");
  const [getItemResponse, getItemReq] = useApiRequest("ITEMS:getItem");
  const [getUserResponse, getUserRequest] = useApiRequest("USERS:getUserInfo");
  const [createItemInsuranceResponse, createItemInsuranceReq] = useApiRequest("ITEMS:createItemInsurance");
  const [updateItemInsuranceResponse, updateItemInsuranceReq] = useApiRequest("ITEMS:updateItemInsurance");
  const [defaultValues, setDefaultValues] = React.useState<EditItemDefaultValues>();
  const [loadedItem, setLoadedItem] = React.useState<boolean>(false);
  const [loadedUser, setLoadedUser] = React.useState<boolean>(false);
  const [handleUpdatedItem, setHandledUpdatedItem] = React.useState<boolean>(false);
  const [handledUpdatedItemInsurance, setHandledUpdatedItemInsurance] = React.useState<boolean | null>(false);
  const [itemInsuranceAction, setItemInsuranceAction] = React.useState<ItemInsuranceAction | null>(null);
  const [updatedItemInsurance, setUpdatedItemInsurance] = React.useState<CreateItemInsuranceRequest | EditItemInsuranceRequest | null>(null);

  // Get the details of the item to update.
  React.useEffect(() => {
    getItemReq({ pathParams: { itemUuid } });
  }, [getItemReq, itemUuid]);

  // Retrieved user details / set default values.
  React.useEffect(() => {
    if (getUserResponse.errorMessage) {
      toast.error(getUserResponse.errorMessage);
      setLoadedUser(true);
    }

    if (getUserResponse.data) {
      setDefaultValues(values => ({
        ...values,
        user: getUserResponse.data 
      }));
      setLoadedUser(true);
    }
  }, [getUserResponse]);

  // Retrieved item detail results and set default values.
  React.useEffect(() => {
    if (getItemResponse.errorMessage) {
      toast.error(getItemResponse.errorMessage);
      setLoadedItem(true);
    }

    if (getItemResponse.data) {
      setDefaultValues(values => ({
        user: null,
        ...values,
        category: getItemResponse.data?.category,
        brand: getItemResponse.data?.brand,
        model: getItemResponse.data?.model,
        color: getItemResponse.data?.color,
        origin: getItemResponse.data?.origin,
        originInfo: getItemResponse.data?.originInfo,
        description: getItemResponse.data?.description,
        posBusinessPartnerUUID: getItemResponse.data?.posBusinessPartnerUUID,
        userUUID: getItemResponse.data?.userUUID,
        isInsured: !!getItemResponse.data?.currentInsurance && getItemResponse.data?.currentInsurance.active,
        insuranceProvider: getItemResponse.data?.currentInsurance?.providerId && getItemResponse.data?.currentInsurance?.active ? getItemResponse.data.currentInsurance.providerId : undefined,
        insuranceStartedAt: getItemResponse.data?.currentInsurance?.dateInsuranceStarted && getItemResponse.data?.currentInsurance?.active ? new Date(getItemResponse.data?.currentInsurance?.dateInsuranceStarted) : undefined,
        insurancePolicyNumber: getItemResponse.data?.currentInsurance?.policyNumber && getItemResponse.data?.currentInsurance?.active ? getItemResponse.data?.currentInsurance?.policyNumber : undefined,
        insuranceQuoteId: getItemResponse.data?.currentInsurance?.quoteId && getItemResponse.data?.currentInsurance?.active ? getItemResponse.data?.currentInsurance?.quoteId : undefined
      }));

      if (getItemResponse.data.userUUID) {
        getUserRequest({ pathParams: { userUuid: getItemResponse.data.userUUID as string } });
      } else {
        toast.error("Not found userUUID");
        setLoadedUser(true);
      }

      setLoadedItem(true);
    }
  }, [getItemResponse, getUserRequest]);

  // On form submission, update the item.
  const onSubmit = (updatedItem: ItemFormInput, userUuid: string) => {
    updateItemReq({
      pathParams: { itemUuid },
      data: {
        ...updatedItem,
        userUUID: userUuid 
      }
    });

    const itemInsuranceAction = inferItemInsuranceAction(updatedItem, defaultValues as EditItemDefaultValues);

    if (itemInsuranceAction === "noAction") {
      setHandledUpdatedItemInsurance(true);
    } else {
      setUpdatedItemInsurance({
        active: updatedItem.isInsured,
        dateInsuranceStarted: updatedItem.insuranceStartedAt,
        providerId: updatedItem.insuranceProvider as string,
        quoteId:  updatedItem.insuranceQuoteId,
        policyNumber: updatedItem.insurancePolicyNumber,
        type: ItemInsuranceType.POLICY
      });
      setItemInsuranceAction(itemInsuranceAction);
    }
  };

  // Handle update item response.
  React.useEffect(() => {
    if (updateItemResponse.data) {
      toast.success("Succesfully updated item");
      setHandledUpdatedItem(true);
    }
  
    if (updateItemResponse.errorMessage) {
      toast.error("There was a problem updating the item");
      console.error(updateItemResponse.errorMessage);
      setHandledUpdatedItem(true);
    }
  }, [updateItemResponse.data, updateItemResponse.errorMessage]);

  // Update / create item insurance when required.
  React.useEffect(() => {
    if (handledUpdatedItemInsurance === false) {
      if (itemInsuranceAction && itemInsuranceAction !== "noAction" && updatedItemInsurance) {
        if (itemInsuranceAction === "create") {
          // Create item insurance.
          createItemInsuranceReq({
            pathParams: { itemUuid },
            data: updatedItemInsurance as CreateItemInsuranceRequest
          });
        } else {
          // Update item insurance.
          updateItemInsuranceReq({
            pathParams: { itemUuid },
            data: updatedItemInsurance as EditItemInsuranceRequest
          });
        }
      }
    }
  }, [
    updatedItemInsurance,
    createItemInsuranceReq,
    updateItemInsuranceReq,
    handledUpdatedItemInsurance,
    itemInsuranceAction,
    itemUuid
  ]);

  // Handle update item insurance response.
  React.useEffect(() => {
    // Create.
    if (createItemInsuranceResponse.data) {
      toast.success("Succesfully created item insurance");
      setHandledUpdatedItemInsurance(true);
    }

    if (createItemInsuranceResponse.errorMessage) {
      toast.error("There was a problem creating the item insurance, the item data may be inconsistent");
      console.error(createItemInsuranceResponse.errorMessage);
      setHandledUpdatedItemInsurance(true);
    }

    // Update.
    if (updateItemInsuranceResponse.data) {
      toast.success("Succesfully updated item insurance");
      setHandledUpdatedItemInsurance(true);
    }

    if (updateItemInsuranceResponse.errorMessage) {
      toast.error("There was a problem updating the item insurance, the item data may be inconsistent");
      console.error(createItemInsuranceResponse.errorMessage);
      setHandledUpdatedItemInsurance(true);
    }
  }, [
    createItemInsuranceResponse.data,
    createItemInsuranceResponse.errorMessage,
    updateItemInsuranceResponse.data,
    updateItemInsuranceResponse.errorMessage,
    setHandledUpdatedItemInsurance
  ]);

  // When handled the creation of the item and insurance, go back to items page.
  React.useEffect(() => {
    if (handledUpdatedItemInsurance && handleUpdatedItem) {
      props.history.push("/items");
    }
  }, [
    handledUpdatedItemInsurance,
    handleUpdatedItem,
    props.history
  ]);

  return (
    <Layout>
      <PageHeader title="Edit Item" backLink={() => props.history.goBack()} />
      <Box>
        {loadedItem && loadedUser ? (
          <ItemForm
            type="update"
            isLoading={updateItemResponse.loading}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
          />
        ) : <LoadingSpinner />}
      </Box>
    </Layout>
  );
};
