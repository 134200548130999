/* eslint-disable no-restricted-globals */
import "react-toastify/dist/ReactToastify.css";

import React from "react";
import { RouteChildrenProps } from "react-router";
import {
  Router, Route, Switch
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styled, { ThemeProvider } from "styled-components";
import { theme } from "src/utils/theme";

import { GlobalStyles } from "src/components/shared/global-styles";

import {
  History,
  createBrowserHistory
} from "history";
import Login from "./public/Login";

import { AuthenticationProvider } from "src/utils/authentication/Authentication";

import NotFound from "./public/NotFound";
import PrivateRoute from "src/components/shared/private-route/PrivateRoute";

import { Grommet } from "grommet";
import ItemsPage from "./protected/admin/items/ItemsPage";
import BusinessUsersPage from "./protected/admin/business-users/BusinessUsersPage";
import AdminUsersPage from "./protected/admin/admin-users/AdminUsersPage";
import PublicUsersPage from "./protected/admin/public-users/PublicUsersPage";
import { NewItemPage } from "./protected/admin/items/NewItemPage";
import { EditItemPage } from "./protected/admin/items/EditItemPage";
import { ViewItemPage } from "./protected/admin/items/ViewItemPage";
import { NewPublicUserPage } from "./protected/admin/public-users/NewPublicUserPage";
import { EditPublicUserPage } from "./protected/admin/public-users/EditPublicUserPage";
import { ViewPublicUserPage } from "./protected/admin/public-users/ViewPublicUserPage";
import { InsurersPage } from "./protected/admin/insurers/InsurersPage";
import { NewInsurerPage } from "./protected/admin/insurers/NewInsurerPage";
import { EditInsurerPage } from "./protected/admin/insurers/EditInsurerPage";
import { ViewInsurerPage } from "./protected/admin/insurers/ViewInsurerPage";

export type PageProps<T, U extends { [K in keyof U]?: string } = {}, S = History.LocationState> = RouteChildrenProps<U, S> & T;

export const dashboardRoutes = [];
const history = createBrowserHistory();

const AppRouter: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Grommet theme={theme}>
        <ToastContainer />
        <GlobalStyles />
        <AuthenticationProvider history={history}>
          <RouterContainer className={"show"}>
            <Router history={history}>
              <Switch>

                <PrivateRoute path="/items" exact={true} component={ItemsPage} />
                <PrivateRoute path="/items/new" exact={true} component={NewItemPage} />
                <PrivateRoute path="/items/:itemUuid/edit" exact={true} component={EditItemPage} />
                <PrivateRoute path="/items/:itemUuid" exact={true} component={ViewItemPage} />

                <PrivateRoute path="/users/public" exact={true} component={PublicUsersPage} />
                <PrivateRoute path="/users/public/new" exact={true} component={NewPublicUserPage} />
                <PrivateRoute path="/users/public/:userUuid/edit" exact={true} component={EditPublicUserPage} />
                <PrivateRoute path="/users/public/:userUuid" exact={true} component={ViewPublicUserPage} />
                <PrivateRoute path="/users/business" exact={true} component={BusinessUsersPage} />
                <PrivateRoute path="/users/admin" exact={true} component={AdminUsersPage} />

                <PrivateRoute path="/insurers" exact={true} component={InsurersPage} />
                <PrivateRoute path="/insurers/new" exact={true} component={NewInsurerPage} />
                <PrivateRoute path="/insurers/:insurerID/edit" exact={true} component={EditInsurerPage} />
                <PrivateRoute path="/insurers/:insurerID" exact={true} component={ViewInsurerPage} />

                {/* Auth */}
                <Route path="/" exact={true} component={Login}/>
                {/* Fallback 4004 */}
                <Route component={NotFound}/>
              </Switch>

            </Router>
          </RouterContainer>
        </AuthenticationProvider>
      </Grommet>

    </ThemeProvider>
  );
};

const RouterContainer = styled.div`
  transition: all 1s ease-in-out;
  opacity: 0;

  &.show {
    opacity: 1;
  }
`;

export default AppRouter;