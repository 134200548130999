import React from "react";
import styled, { css } from "styled-components";
import { Box, BoxProps } from "grommet";

export const Card: React.FC<BoxProps & {maxWidth?: string}> = props => {
  return (
    <StyledCard {...props} >
      {props.children}
    </StyledCard>
  );
};

const StyledCard = styled(Box)<{maxWidth?: string}>`
  ${({ theme, maxWidth }) => css`
    width: 100%;
    max-width: ${maxWidth || "none"};
    background: ${theme.colors.Neutral100};
    box-shadow: 0px 0px 8px -3px ${theme.colors.Neutral600};
    border-radius: ${theme.borderRadius.medium};
    overflow: hidden;
  `};
`;
