import * as React from "react";

import {
  checkIfTokenExpired,
  refreshAuthAsync,
  useAuthenticationContext
} from "../Authentication";

/** Check auth*/
const useCheckUserIsAuthenticated = () => {
  const { authTokenState, setAuthTokenState } = useAuthenticationContext();

  return React.useCallback(async () => {
    try {
      // not authenticated
      if (!authTokenState) {
        return null;
      }

      if (checkIfTokenExpired(authTokenState.token.expirationTime)) {
        try {
          const newToken = await refreshAuthAsync(authTokenState);

          setAuthTokenState(newToken);

          return newToken;
        } catch (error) {
          setAuthTokenState(null);

          return null;
        }
      }

      return authTokenState;
    } catch (e) {
      throw new Error("Unknown error occurred");
    }
  }, [authTokenState, setAuthTokenState]);
};

export default useCheckUserIsAuthenticated;
