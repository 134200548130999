import React from "react";
import styled, { css } from "styled-components";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DateInputProps {
  isInvalid?: boolean;
}

/**
 * Text input field
 */
const StyledDateInput = styled(DatePicker)
  .attrs<DateInputProps>(({ isInvalid }) => ({ "aria-invalid": isInvalid || false }))<DateInputProps>`
  ${({ theme, isInvalid }) => css`
    font-family: ${theme.font.family.secondary};
    padding: 1rem;
    width: 100%;
    height: 4.8rem;
    font-weight: ${theme.font.weights.medium};
    font-size: ${theme.font.sizes.bodySmall};
    border: 0.1rem solid ${!isInvalid ? theme.colors.Neutral800 : theme.actions.error};
    border-radius: ${theme.borderRadius.small};
    outline: none;
  `};
`;

export const DateInput: React.FC<DateInputProps & ReactDatePickerProps> = props => <StyledDateInput className="red-border" {...props} />;