import React from "react";
import { Box } from "grommet";

const TableToolBar: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { children, ...rest } = props;

  return (
    <Box direction="row" align="center" justify="end" pad="small" {...rest}>
      {children}
    </Box>
  );
};

export default TableToolBar;