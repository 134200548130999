import React from "react";
import { CellProps } from "react-table";
import styled from "styled-components";

export const TooltipCell: React.FC<CellProps<Record<string, unknown>>> = ({ cell: { value }, column: { align = "left" } }) => (
  <Tooltip text={value} align={align} />
);

interface Tooltip {
  text: string;
  tooltip?: string;
  align: string;
}

export const Tooltip: React.FC<Tooltip> = ({
  text, tooltip = text, align 
}) => {
  return (
    <Wrapper align={align}>
      <span title={tooltip}>
        {text}
      </span>
    </Wrapper>
  );
};

const Wrapper = styled.div<{align: string}>`
  text-align: ${props => props.align};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;