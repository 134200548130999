import { ApiRouteDefinition } from "../useApiRequest";
import { ItemInsuranceProviderEnum } from "./items.api";
import {
  PickParams, StringResponse, OrderBy, BasePaginatedQuery
} from "../api";

export const InsurerRoutes = {
  /* Create an insurer */
  "INSURERS:createInsurer": {
    path: "/insurance/providers",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<StringResponse, CreateInsurerRequest, PickParams<"insurerID">, null>,
  
  /* Update an insurer */
  "INSURERS:updateInsurer": {
    path: "/insurance/providers/:insurerID",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<StringResponse, UpdateInsurerRequest, PickParams<"insurerID">, null>,

  /* Retrieve an insurer*/
  "INSURERS:getInsurer": {
    path: "/insurance/providers/:insurerID",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<InsurerDTO, null, PickParams<"insurerID">>,

  /* Get all insurers */
  "INSURERS:getAllInsurers": {
    path: "/insurance/providers",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
      InsurerDTO[],
      null,
      null,
      null
    >
};

type CreateInsurerRequest = Partial<InsurerDTO>;
type UpdateInsurerRequest = Partial<InsurerDTO>;

export interface InsurerDTO {
  ID?: string;
  providerName: ItemInsuranceProviderEnum;
  enabled: boolean;
  base64RedirectUrl: string;
  base64CallbackUrl: string;
  base64LogoUrl: string;
  insuranceDetails: string;
  smallLetter: string;
  successDetails: string;
  successTitle: string;
  title: string;
  dateCreated?: Date;
  dateDeleted?: Date;
  dateUpdated?: Date;
  deletedBy?: string;
  logicalDeleted?: boolean;
}

export interface PaginatedCollectionResponseInsurerDTO {
  items: Array<InsurerDTO>;
  itemsCount: number;
  page: number;
  pageSize: number;
  total: number;
}

export interface InsurersQuery extends BasePaginatedQuery {
  query?: string;
  sortBy?: OrderBy;
}