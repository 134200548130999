import styled from "styled-components";
import React from "react";
import { Text } from "../text";
import { Button } from "../button";
import { LinkPrevious } from "grommet-icons";

interface PageHeaderProps {
  backLink?: () => void;
  title?: string;
  callToAction?: React.ReactElement;
}

export const PageHeader: React.FC<PageHeaderProps> = props => {
  return (
    <Wrapper>
      {!!props.backLink &&
      <BackLinkWrapper>
        <Button plain={true} color="light" onClick={props.backLink} label="Back" iconDirection="left" icon={<LinkPrevious />} />
      </BackLinkWrapper>
      }
      {(props.title || props.callToAction) && (
        <Header>
          <Text as="h2" bold={true}>{props.title}</Text>
          {props.callToAction}
        </Header>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const BackLinkWrapper = styled.div`
  display: flex;
`;

const Header = styled.header`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
