import { ObjectId } from "../api";
import {
  DeviceTypeEnum, AppTypeEnum, SocialTypeEnum 
} from "../shared-types";

export interface AuthenticationRequest {
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  appType: AppTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  deviceToken?: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  deviceType: DeviceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  socialMediaSecret?: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  socialMediaToken?: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequest
   */
  type?: SocialTypeEnum;
}

/**
 *
 * @export
 * @interface AuthenticationResponse
 */
export interface AuthenticationResponse {
  /**
   *
   * @type {string}
   * @memberof AuthenticationResponse
   */
  business?: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationResponse
   */
  role: RoleEnum;
  /**
   *
   * @type {Token}
   * @memberof AuthenticationResponse
   */
  token: Token;
  /**
   *
   * @type {string}
   * @memberof AuthenticationResponse
   */
  userUUID: string;
}

/**
 *
 * @export
 * @interface Token
 */
export interface Token {
  expirationTime: number;

  /**
   *
   * @type {string}
   * @memberof Token
   */
  value: string;

  /**
   *
   * @type {string}
   * @memberof Token
   */
  createdBy?: string;

  /**
   *
   * @type {number}
   * @memberof TokenDTO
   */
  timeToLive?: number;

  /**
   *
   * @type {string}
   * @memberof Token
   */
  dateCreated?: string;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  dateDeleted?: string;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  dateUpdated?: string;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  deletedBy?: string;

  /**
   *
   * @type {ObjectId}
   * @memberof Token
   */
  id?: ObjectId;
  /**
   *
   * @type {boolean}
   * @memberof Token
   */
  logicalDeleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  updatedBy?: string;
  /**
   *
   * @type {ObjectId}
   * @memberof Token
   */
  userID?: ObjectId;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  uuid?: string;

  /**
   *
   * @type {number}
   * @memberof Token
   */
  version?: number;
}

export interface GrantedAuthority {
  authority?: string;
}

export interface TokenDTO {
  expirationTime?: number;
  timeToLive?: number;
  value?: string;
}

export enum RoleEnum {
  USER = "USER",
  COMPANYADMIN = "COMPANY_ADMIN",
  COMPANY = "COMPANY",
  ENDUSERMANAGEMENT = "END_USER_MANAGEMENT",
  PARTNERMANAGEMENT = "PARTNER_MANAGEMENT",
  PROPERTYMANAGEMENT = "PROPERTY_MANAGEMENT",
  FOUNDSTAFFMANAGEMENT = "FOUND_STAFF_MANAGEMENT",
  TEMPORARYINDB = "TEMPORARY_IN_DB"
}