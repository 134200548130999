import { $enum } from "ts-enum-util";
import { ItemDTOStatusEnum } from "src/utils/api";

export const mapItemStatus = (status: ItemDTOStatusEnum) => {
  return $enum.mapValue(status).with({
    [ItemDTOStatusEnum.FOUND]: "Found",
    [ItemDTOStatusEnum.LOST]: "Lost",
    [ItemDTOStatusEnum.PENDING_USER_FOUND_ITEM]: "Pending user found",
    [ItemDTOStatusEnum.PENDING_USER_LOST_ITEM]: "Pending user lost",
    [ItemDTOStatusEnum.PENDING_USER_PREREGISTERED_ITEM]: "Pending user registered",
    [ItemDTOStatusEnum.PREREGISTERED]: "Registered",
    [ItemDTOStatusEnum.RETURNED]: "Returned"
  });
};