import React from "react";
import { toast } from "react-toastify";
import { Box, Menu } from "grommet";
import { PageHeader } from "src/components/shared/layout";
import { Text } from "src/components/shared/text";
import { Layout } from "src/components/shared/layout/Layout";
import styled, { css } from "styled-components";

import { PageProps } from "src/pages/Router";
import {
  useApiRequest, ItemDTO, UserDTO, ItemInsuranceType
} from "src/utils/api";

import { LoadingSpinner } from "src/components/shared/loading-spinner";
import {
  InfoPanel, InfoColumn, InfoBox
} from "src/components/shared/info";
import formatDate from "date-fns/format";

interface ViewItemPageParams {
  itemUuid: string;
}

export const ViewItemPage: React.FC<PageProps<Record<string, unknown>, ViewItemPageParams>> = props => {
  const [userUuid, setUserUuid] = React.useState<string>();
  const [user, setUser] = React.useState<UserDTO>();
  const [item, setItem] = React.useState<ItemDTO>();
  const [insurerName, setInsurerName] = React.useState<string | null>();
  const [loading, setLoading] = React.useState(true);
  const [getItemResponse, getItemRequest] = useApiRequest("ITEMS:getItem");
  const [getUserResponse, getUserRequest] = useApiRequest("USERS:getUserInfo");
  const [getInsurerResponse, getInsurerRequest] = useApiRequest("INSURERS:getInsurer");
  const itemUuid = React.useMemo(() => props.match?.params.itemUuid || "", [props.match]);

  // Request the user by id based off item info.
  React.useEffect(() => {
    if (!user && userUuid) {
      getUserRequest({ pathParams: { userUuid } });
    }
  }, [
    getUserRequest,
    userUuid,
    user
  ]);

  // Detect the response for get user by id
  React.useEffect(() => {
    if (getUserResponse.data) {
      setUser(getUserResponse.data);
      setLoading(false);
    }

    if (getUserResponse.errorMessage) {
      toast.error(getUserResponse.errorMessage);
    }
  }, [getUserResponse]);

  // Request the item by id based off path params
  React.useEffect(() => {
    if (!item) {
      getItemRequest({ pathParams: { itemUuid } });
    }
  }, [
    getItemRequest,
    item,
    itemUuid
  ]);

  // Detect the response for get item by id
  React.useEffect(() => {
    if (getItemResponse.data) {
      setItem(getItemResponse.data);
      setLoading(false);

      if (getItemResponse.data.userUUID) {
        setUserUuid(getItemResponse.data.userUUID);
      }

      if (getItemResponse.data.currentInsurance) {
        getInsurerRequest({ pathParams: { insurerID: getItemResponse.data.currentInsurance.providerId as string } });
      }
    }

    if (getItemResponse.errorMessage) {
      toast.error(getItemResponse.errorMessage);
    }
  }, [
    getInsurerRequest,
    getItemResponse,
    props.history,
    props.match
  ]);

  // Detect the response for get insurer by id
  React.useEffect(() => {
    if (getInsurerResponse.data) {
      setInsurerName(getInsurerResponse.data.providerName);
      setLoading(false);
    }
  
    if (getInsurerResponse.errorMessage) {
      toast.error(getInsurerResponse.errorMessage);
    }
  }, [
    getInsurerResponse,
    props.history,
    props.match
  ]);

  return (
    <Layout>
      <PageHeader 
        backLink={() => props.history.goBack()}
      />
      {loading && <LoadingSpinner />}
      
      <Box direction="column">
        {item && (
          <Box width={{ max:"15rem" }}>
            <StyledMenu
              dropBackground="Neutral500"
              color="white"
              justifyContent="between"
              dropProps={{
                align: {
                  top: "bottom",
                  right: "right"
                }
              }}
              items={[
                {
                  label: "Edit item",
                  onClick: () => props.history.push(`/items/${itemUuid}/edit`)

                }
              ]}
              label="Actions"
            />
          </Box>
        )}
        <Box width="100%">
          {item && (
            <>
              <InfoPanel label="Item overview">
                <InfoColumn>
                  <InfoBox label="Category" value={item.category} />
                  <InfoBox label="Brand" value={item.brand} />
                  <InfoBox label="Model" value={item.model} />
                  <InfoBox label="Colour" value={item.color} />
                  
                </InfoColumn>
              </InfoPanel>
              <InfoPanel label="Item details">
                <InfoColumn>
                  {
                    user ?
                      <InfoBox label="User" value={
                        user ?
                          <Text as="a" onClick={() => props.history.push(`/users/public/${user?.id}`)}>
                            {`${user?.firstName ? user?.firstName : ""} ${user?.lastName ? user?.lastName : ""} (${user?.emailAddress ? user?.emailAddress : ""})`}
                          </Text> : 
                          <Text >-</Text>
                      } /> : <InfoBox label="User id" value={`${item.userUUID ? item.userUUID : ""} (Not found user details)`} />
                  }
                  <InfoBox label="Origin" value={item.origin} />
                  <InfoBox label="Origin information" value={item.originInfo} />
                  <InfoBox label="POS Business Partner UUID" value={item.posBusinessPartnerUUID} />
                  <InfoBox label="Description" value={item.description} />
                </InfoColumn>
              </InfoPanel>
              <InfoPanel label="Insurance details">
                <InfoColumn>
                  <InfoBox label="Is insured?" value={item.currentInsurance && item.currentInsurance.active && item.currentInsurance.type === ItemInsuranceType.POLICY ? "Yes" : "No"} />
                  {
                    item.currentInsurance && item.currentInsurance.active && item.currentInsurance.type === ItemInsuranceType.POLICY &&
                    <>
                      <InfoBox label="Provider" value={insurerName} />
                      <InfoBox label="Policy number" value={item.currentInsurance?.policyNumber} />
                      <InfoBox label="Quote id" value={item.currentInsurance?.quoteId} />
                      <InfoBox label="Started at" value={item.currentInsurance?.dateInsuranceStarted ? formatDate(new Date(item.currentInsurance.dateInsuranceStarted), "dd/MM/yyyy") : null} />
                    </>
                  }
                </InfoColumn>
              </InfoPanel>
            </>
          )}
        </Box>
      </Box>
      
    </Layout>
  );
};

const StyledMenu = styled(Menu)`
  ${({ theme }) => css`
    background: ${theme.colors.Primary500};
    border-radius: ${theme.borderRadius.large};
  `};
`;
