import React from "react";
import { toast } from "react-toastify";
import { Box, Menu } from "grommet";
import { PageHeader } from "src/components/shared/layout";
import { Layout } from "src/components/shared/layout/Layout";
import styled, { css } from "styled-components";
import formatDate from "date-fns/format";

import { PageProps } from "src/pages/Router";
import { useApiRequest, UserDTO } from "src/utils/api";
import { PublicUserItemsTable } from "src/components/public-users/PublicUserItemsTable";

import { LoadingSpinner } from "src/components/shared/loading-spinner";
import {
  InfoPanel, InfoColumn, InfoBox
} from "src/components/shared/info";

interface ViewPublicUserPageParams {
  userUuid: string;
}

export const ViewPublicUserPage: React.FC<PageProps<Record<string, unknown>, ViewPublicUserPageParams>> = props => {
  const [user, setUser] = React.useState<UserDTO>();
  const [loading, setLoading] = React.useState(true);
  const [getUserResponse, getUserRequest] = useApiRequest("USERS:getUserInfo");
  const userUuid = React.useMemo(() => props.match?.params.userUuid || "", [props.match]);

  // Request the user by id based off path params
  React.useEffect(() => {
    if (!user) {
      getUserRequest({ pathParams: { userUuid } });
    }
  }, [
    getUserRequest,
    user,
    userUuid
  ]);

  // Detect the response for get user by id
  React.useEffect(() => {
    if (getUserResponse.data) {
      setUser(getUserResponse.data);
      setLoading(false);
    }

    if (getUserResponse.errorMessage) {
      toast.error(getUserResponse.errorMessage);
    }
  }, [
    getUserResponse,
    props.history,
    props.match
  ]);

  return (
    <Layout>
      <PageHeader 
        backLink={() => props.history.goBack()}
      />
      {loading && <LoadingSpinner />}
      
      <Box direction="column">
        {user && (
          <Box width={{ max:"15rem" }}>
            <StyledMenu
              dropBackground="Neutral500"
              color="white"
              justifyContent="between"
              dropProps={{
                align: {
                  top: "bottom",
                  right: "right"
                }
              }}
              items={[
                {
                  label: "Edit user",
                  onClick: () => props.history.push(`/users/public/${userUuid}/edit`)

                }
              ]}
              label="Actions"
            />
          </Box>
        )}
        <Box width="100%">
          {user && (
            <>
              <InfoPanel label="User details">
                <InfoColumn>
                  <InfoBox label="First name" value={user?.firstName} />
                  <InfoBox label="Last name" value={user?.lastName} />
                  <InfoBox label="Email" value={user?.emailAddress} />
                  <InfoBox label="Birth date" value={user?.birthdate ? formatDate(new Date(user.birthdate), "dd/MM/yyyy") : undefined} />
                  <InfoBox label="Phone number" value={user?.phoneNumber} />
                </InfoColumn>
              </InfoPanel>
              <InfoPanel label="User address">

                <InfoColumn>
                  <InfoBox label="Address Line 1" value={user?.locationDTO?.address1} />
                  <InfoBox label="Address Line 2" value={user?.locationDTO?.address2} />
                  <InfoBox label="City" value={user?.locationDTO?.city} />
                  <InfoBox label="Postcode" value={user?.locationDTO?.postCode} />
                </InfoColumn>
              </InfoPanel>
    
              <InfoPanel label="Origin information">
                <InfoColumn>
                  <InfoBox label="Origin" value={user?.origin} />
                  <InfoBox label="Origin information" value={user?.originInfo} />
                  <InfoBox label="POS Business Partner UUID" value={user?.posBusinessPartnerUUID} />
                </InfoColumn>
              </InfoPanel>
            </>
          )}
        </Box>
      </Box>

      <Box margin={{ top: "3rem" }}>
        <PublicUserItemsTable history={props.history} userUuid={userUuid} />
      </Box>
      
    </Layout>
  );
};

const StyledMenu = styled(Menu)`
  ${({ theme }) => css`
    background: ${theme.colors.Primary500};
    border-radius: ${theme.borderRadius.large};
  `};
`;
