import React from "react";
import styled from "styled-components";
import { TableCellProps } from "grommet";

type TableBodyCellProps = React.HTMLAttributes<HTMLDivElement> & TableCellProps;

const TableBodyCell: React.FC<TableBodyCellProps> = props => {
  const { children, ...rest } = props;

  return (
    <TableBodyCellStyled {...rest}>
      {children}
    </TableBodyCellStyled>
  );
};

const TableBodyCellStyled = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1%;
  padding-right: 1%;
  cursor: pointer;
`;

export default TableBodyCell;