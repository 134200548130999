import * as React from "react";

import axios, { AxiosRequestConfig } from "axios";

import { compile } from "path-to-regexp";

import { useCheckUserIsAuthenticated } from "src/utils/authentication";
import { createLogger } from "src/utils/logger";

import {
  FileRoutes,
  ItemRoutes,
  LocationRoutes,
  MatchRoutes,
  NotificationRoutes,
  UserRoutes,
  InsurerRoutes
} from "./routes";

const logger = createLogger("API");

const ApiRoutes = {
  ...UserRoutes,
  ...LocationRoutes,
  ...NotificationRoutes,
  ...ItemRoutes,
  ...MatchRoutes,
  ...FileRoutes,
  ...InsurerRoutes
};

const baseUrl = process.env.REACT_APP_BASE_URL;
const defaultHeaders = { "Content-Type": "application/json" };
const UnknownErrorMessage = "An Unknown Error occurred";

interface ResponseState<N extends RequestName> {
  data: RoutesType[N]["_"] | null;
  loading: boolean;
  errorMessage: string | null;
}
export type UseApiRequestType<N extends RequestName> = [
  ResponseState<N>,
  (requestInfo: ConditionalRequestData<RoutesType[N]>) => void
];

const useApiRequest = <N extends RequestName>(requestName: N) => {
  const selectedRoute = ApiRoutes[requestName];

  // Interpolate the path parameters to the url
  const getUrlPath = React.useCallback((path: string, requestInfo: ConditionalRequestData<RoutesType[N]>) => {
    if (requestInfo.pathParams) {
      const toPath = compile(path);

      return toPath(requestInfo.pathParams);
    }

    return path;
  },
  []);

  return useApiFetch(config => {
    if (!baseUrl) {
      throw new Error("No BASE_URL set in environment");
    }

    return {
      url: `${baseUrl}${getUrlPath(selectedRoute.path, config)}`,
      authenticate: selectedRoute.authenticate,
      method: selectedRoute.method,
      ...config
    };
  }, requestName);
};

type CustomAxiosConfig = AxiosRequestConfig & { authenticate: boolean };

const useApiFetch = <N extends RequestName>(
  fn: (config: ConditionalRequestData<RoutesType[N]>) => CustomAxiosConfig,
  requestName: N
): UseApiRequestType<N> => {
  const [config, setConfig] = React.useState<CustomAxiosConfig | null>(null);
  const userIsAuthenticed = useCheckUserIsAuthenticated();

  const [responseState, setResponseState] = React.useState<ResponseState<N>>({
    loading: false,
    errorMessage: null,
    data: null
  });

  const handleSuccessResponse = React.useCallback(response => {
    // success response
    logger.info(`API:${requestName}:SUCCESS`, response.data);

    if (response.data) {
      if (typeof response.data === "string") {
        setResponseState(currentState => ({
          ...currentState,
          loading: false,
          errorMessage: null,
          data: { message: response.data }
        }));
      } else if (typeof response.data === "object") {
        setResponseState(currentState => ({
          ...currentState,
          loading: false,
          errorMessage: null,
          data: response.data
        }));
      }
    } else {
      setResponseState(currentState => ({
        ...currentState,
        loading: false,
        errorMessage: null,
        data: { message: "Success!" }
      }));
    }
  },
  [requestName]);

  const handleErrorResponse = React.useCallback(error => {
    logger.info(`API:${requestName}:FAILURE`, error);

    let errMessage = UnknownErrorMessage;

    // error response
    if (error.response && error.response.data) {
      if (error.response.data.message) {
        errMessage = error.response.data.message;
      } else if (error.response.data.error && error.response.data.error.message) {
        errMessage = error.response.data.error.message;
      } else if (typeof error.response.data === "string") {
        errMessage = error.response.data;
      }
    }

    setResponseState({
      loading: false,
      errorMessage: errMessage,
      data: null
    });
  },
  [requestName]);

  React.useEffect(() => {
    if (!config) {
      return;
    }

    logger.info(`API:${requestName}:START`, config);

    setResponseState({
      loading: true,
      errorMessage: null,
      data: null
    });

    try {
      if (config.authenticate) {
        userIsAuthenticed().then(authState => {
          if (!authState) {
            setResponseState({
              loading: false,
              errorMessage: "User is not authenticated",
              data: null
            });

            return;
          }

          axios({
            ...config,
            headers: {
              ...defaultHeaders,
              ...(config.headers || {}),
              Authorization: authState.token.value
            }
          })
            .then(handleSuccessResponse)
            .catch(handleErrorResponse);
        });
      } else {
        axios(config).then(handleSuccessResponse).catch(handleErrorResponse);
      }
    } catch (error) {
      logger.info(`API:${requestName}:FAILURE`, error);

      setResponseState({
        loading: false,
        errorMessage: "Unknown error occurred",
        data: null
      });
    }
  }, [
    config,
    handleErrorResponse,
    handleSuccessResponse,
    userIsAuthenticed,
    requestName
  ]);

  const triggerRequest = React.useCallback((conf: ConditionalRequestData<RoutesType[N]>) => setConfig(fn(conf)),
    // avoid recursive loop!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  return [responseState, triggerRequest];
};

// Api route definition gernic typing for Data and Params
export interface ApiRouteDefinition<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Response extends Record<string, any>,
  Data = null,
  Params extends Record<string, string> | null = null,
  Query = null
> extends Pick<AxiosRequestConfig, "headers"> {
  path: string;
  method: "GET" | "PUT" | "POST" | "DELETE";
  authenticate: boolean;
  pathParams: Params extends null ? never : Params;
  data: Data extends null ? never : Data;
  params: Query extends null ? never : Query;
  // placeholder to type the response
  _: Response;
}

// Allows pathParams,data and params to be excluded
type ConditionalRequestData<
  Request extends ApiRouteDefinition<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<string, any>,
    unknown,
    Record<string, string> | null,
    unknown
  >
> = (
  Request["data"] extends never
    ? { data?: never }
    : Request["data"] extends FormData
      ? { data?: Request["data"] }
      : { data: Request["data"] }
  ) & (
    Request["pathParams"] extends never
      ? { pathParams?: never }
      : { pathParams: Request["pathParams"] }
  ) & (
    Request["params"] extends never
      ? { params?: never }
      : { params: Request["params"] }
  ) & (
    { headers?: Request["headers"] }
  );

type RoutesType = typeof ApiRoutes;
type RequestName = keyof RoutesType;

export default useApiRequest;
