import * as React from "react";

import axios from "axios";
import {
  AppTypeEnum,
  AuthenticationRequest,
  AuthenticationResponse,
  DeviceTypeEnum,
  SocialTypeEnum
} from "src/utils/api";
import { useAuthenticationContext } from "../Authentication";

const baseUrl = process.env.REACT_APP_BASE_URL;

/** Sign in  */
const useSignIn = () => {
  const { setAuthTokenState } = useAuthenticationContext();

  return React.useCallback(async (email: string, password: string) => {
    const data: AuthenticationRequest = {
      email,
      password,
      appType: AppTypeEnum.BUSINESS,
      type: SocialTypeEnum.DEFAULT,
      deviceType: DeviceTypeEnum.DEFAULT
    };

    try {
      const { data: newToken } = await axios.request<
        AuthenticationResponse
        >({
          method: "POST",
          url: `${baseUrl}/authentication/tokens/regular`,
          data
        });

      setAuthTokenState(newToken);

      return;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else if (error?.response?.data?.error?.message) {
        throw new Error(error.response.data.error.message);
      } else if (typeof error?.response?.data === "string") {
        throw new Error(error.response.data);
      } else {
        throw new Error("Unknown error occurred");
      }
    }
  },
  [setAuthTokenState]);
};

export default useSignIn;
