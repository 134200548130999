import * as React from "react";

import styled, { css } from "styled-components";
import { FieldError } from "react-hook-form";

interface FormErrorProps extends React.HTMLAttributes<HTMLSpanElement> {
  message?: FieldError | string | null;
}

/**
 * Form error message
 */
export const FormError: React.FC<FormErrorProps> = props => {
  const { message, ...rest } = props;

  // If there isn't a message provided, return.
  if (!message) {
    return null;
  }

  return (
    <FormErrorStyled role="alert" {...rest}>
      {message}
    </FormErrorStyled>
  );
};

const FormErrorStyled = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    color: ${theme.actions.error};
    font-size: ${theme.font.sizes.small};
  `};
`;