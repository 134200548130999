import React from "react";

import { Table } from "src/components/shared/table";
import { Column, Row } from "react-table";
import { useApiRequest } from "src/utils/api";

import { Button } from "src/components/shared/button";
import { Layout } from "src/components/shared/layout/Layout";
import { PageProps } from "src/pages/Router";
import { PageHeader, Card } from "src/components/shared/layout";

import { InsurerDTO } from "src/utils/api";
import { toast } from "react-toastify";

type InsurersTable = Pick<InsurerDTO, "ID" | "providerName" | "enabled">;

const columns: Column<InsurersTable>[] = [
  {
    Header: "Name",
    accessor: "providerName"
  },
  {
    Header: "Enabled",
    accessor: "enabled",
    Cell: ({ value }) => value ? "Yes" : "No"
  }
];

const hiddenColumns = ["id"];

export const InsurersPage: React.FC<PageProps<{}>> = props => {
  const [data, setData] = React.useState<InsurerDTO[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [getAllInsurersResponse, getAllInsurersRequest] = useApiRequest("INSURERS:getAllInsurers");

  React.useEffect(() => {
    // Set the loading state
    setLoading(true);
    getAllInsurersRequest({});
  }, [getAllInsurersRequest]);

  const handleOnClick = React.useCallback((row: Row<InsurersTable>) => {
    props.history.push(`/insurers/${row.original.ID}`);
  }, [props.history]);

  React.useEffect(() => {
    if (getAllInsurersResponse.data) {
      setData(getAllInsurersResponse.data);
      setLoading(false);
    }

    if (getAllInsurersResponse.errorMessage) {
      toast.error(getAllInsurersResponse.errorMessage);
    }
  }, [getAllInsurersResponse]);

  return (
    <Layout>
      <PageHeader title="Insurance providers" callToAction={<Button label="New" onClick={() => props.history.push("/insurers/new")} />} />
      <Card>
        <Table<InsurersTable>
          name={"InsurersTable"}
          title={`${data.length} Insurance providers`}
          columns={columns}
          data={data}
          loading={loading}
          onClick={handleOnClick}
          defaultHiddenColumns={hiddenColumns}
          usePagination={false}
          useFilters={false}
          useHideColumns={true}
          useResizeColumns
          useSortBy={false}
        />
      </Card>
    </Layout>
  );
};
