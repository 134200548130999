import React from "react";
import { toast } from "react-toastify";
import { Box } from "grommet";
import { PageHeader } from "src/components/shared/layout";
import { Layout } from "src/components/shared/layout/Layout";

import { PageProps } from "src/pages/Router";
import {
  useApiRequest, CreateItemInsuranceRequest, ItemInsuranceType 
} from "src/utils/api";
import { ItemForm, ItemFormInput } from "src/components/items/ItemForm";

export const NewItemPage: React.FC<PageProps<{}>> = props => {
  const [createItem, createItemReq] = useApiRequest("ITEMS:adminPregisterItem");
  const [createItemInsurance, createItemInsuranceReq] = useApiRequest("ITEMS:createItemInsurance");
  const [newItemInsurance, setNewItemInsurance] = React.useState<CreateItemInsuranceRequest | null>(null);
  const [newItemId, setNewItemId] = React.useState<string | null>(null);
  const [handledNewItem, setHandledNewItem] = React.useState<boolean>(false);
  const [handledNewItemInsurance, setHandledNewItemInsurance] = React.useState<boolean | null>(false);

  // On form submission, create the item.
  const onSubmit = (item: ItemFormInput, userUuid: string) => {
    // Create item.
    createItemReq({
      pathParams: { userUuid },
      data: {
        category: item.category,
        brand: item.brand,
        model: item.model,
        color: item.color,
        origin: item.origin,
        originInfo: item.originInfo,
        description: item.description,
        posBusinessPartnerUUID: item.posBusinessPartnerUUID,
        userUUID: userUuid
      }
    });

    if (!item.isInsured) {
      setHandledNewItemInsurance(true);
    } else {
      setNewItemInsurance({
        active: true,
        dateInsuranceStarted: item.insuranceStartedAt,
        providerId: item.insuranceProvider as string,
        quoteId:  item.insuranceQuoteId,
        policyNumber: item.insurancePolicyNumber,
        type: ItemInsuranceType.POLICY
      });
    }
  };

  // Handle create item response.
  React.useEffect(() => {
    if (createItem.data) {
      setHandledNewItem(true);
      setNewItemId(createItem.data.id);
    }

    if (createItem.errorMessage) {
      toast.error("There was a problem creating the item");
      console.error(createItem.errorMessage);
      setHandledNewItem(true);
    }
  }, [createItem.data, createItem.errorMessage]);

  // Create item insurance when required.
  React.useEffect(() => {
    if (handledNewItemInsurance === false) {
      if (newItemId && newItemInsurance) {
        // Create item insurance.
        createItemInsuranceReq({
          pathParams: { itemUuid: newItemId },
          data: newItemInsurance
        });
      }
    }
  }, [
    createItemInsuranceReq,
    newItemInsurance,
    handledNewItemInsurance,
    newItemId
  ]);

  // Handle create item insurance response.
  React.useEffect(() => {
    if (createItemInsurance.data) {
      setHandledNewItemInsurance(true);
    }

    if (createItemInsurance.errorMessage) {
      toast.error("There was a problem creating the item insurance, the item data may be inconsistent");
      console.error(createItemInsurance.errorMessage);
      setHandledNewItemInsurance(true);
    }
  }, [
    createItemInsurance.data,
    createItemInsurance.errorMessage,
    setHandledNewItemInsurance
  ]);

  // When handled the creation of the item and insurance, go back to items page.
  React.useEffect(() => {
    if (handledNewItem && handledNewItemInsurance) {
      toast.success("Successfully created item!");
      props.history.push("/items");
    }
  }, [
    handledNewItem,
    handledNewItemInsurance,
    props.history
  ]);

  return (
    <Layout>
      <PageHeader title="New Item" backLink={() => props.history.goBack()} />
      <Box>
        <ItemForm
          type="create"
          isLoading={false}
          onSubmit={onSubmit}
        />
      </Box>

    </Layout>
  );
};
