import { AvailableParams, ObjectId } from "../api";
import { ApiRouteDefinition } from "../useApiRequest";

export const FileRoutes = {
  "FILES:getFile": {
    path: "/images/file-uuid",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    { file: string },
    null,
    Pick<AvailableParams, "fileUuid">
  >
};

export type FileRouteType = typeof FileRoutes;
export interface FileResource {
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  awsBucket?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  awsKey?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  contentName?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  contentType?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  dateCreated?: number;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  dateDeleted?: number;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  dateUpdated?: number;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  deletedBy?: string;
  /**
   *
   * @type {ObjectId}
   * @memberof FileResource
   */
  id?: ObjectId;
  /**
   *
   * @type {boolean}
   * @memberof FileResource
   */
  logicalDeleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  updatedBy?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  userUUID?: string;
  /**
   *
   * @type {string}
   * @memberof FileResource
   */
  uuid?: string;
  /**
   *
   * @type {number}
   * @memberof FileResource
   */
  version?: number;
}
/**
 *
 * @export
 * @interface FileResourceDTO
 */
export interface FileResourceDTO {
  /**
   *
   * @type {string}
   * @memberof FileResourceDTO
   */
  awsBucket?: string;
  /**
   *
   * @type {string}
   * @memberof FileResourceDTO
   */
  awsKey?: string;
  /**
   *
   * @type {string}
   * @memberof FileResourceDTO
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof FileResourceDTO
   */
  contentName?: string;
  /**
   *
   * @type {string}
   * @memberof FileResourceDTO
   */
  contentType?: string;
  /**
   *
   * @type {boolean}
   * @memberof FileResourceDTO
   */
  receipt?: boolean;
  /**
   *
   * @type {string}
   * @memberof FileResourceDTO
   */
  userUUID?: string;
  /**
   *
   * @type {string}
   * @memberof FileResourceDTO
   */
  uuid?: string;
}