import React from "react";
import { toast } from "react-toastify";
import { Box } from "grommet";
import { PageHeader } from "src/components/shared/layout";
import { Layout } from "src/components/shared/layout/Layout";

import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { InsurerForm, InsurerFormInput } from "src/components/insurers/InsurerForm";
import { LoadingSpinner } from "src/components/shared/loading-spinner";

interface EditInsurerPageParams {
  insurerID: string;
}

export const EditInsurerPage: React.FC<PageProps<{}, EditInsurerPageParams>> = props => {
  const insurerID = React.useMemo(() => props.match?.params.insurerID || "", [props.match]);
  const [updateInsurerResponse, updateInsurerRequest] = useApiRequest("INSURERS:updateInsurer");
  const [getInsurerResponse, getInsurerRequest] = useApiRequest("INSURERS:getInsurer");
  const [defaultValues, setDefaultValues] = React.useState<Partial<InsurerFormInput>>();

  // On form submission
  const onSubmit = (insurer: InsurerFormInput) => {
    // Encode base64 url strings.
    updateInsurerRequest({
      pathParams: { insurerID },
      data: {
        providerName: insurer.providerName,
        enabled: insurer.enabled,
        base64LogoUrl: Buffer.from(insurer.logoUrl, "utf-8").toString("base64"),
        base64RedirectUrl: Buffer.from(insurer.redirectUrl, "utf-8").toString("base64"),
        base64CallbackUrl: Buffer.from(insurer.callbackUrl, "utf-8").toString("base64"),
        title: insurer.title,
        insuranceDetails: insurer.insuranceDetails,
        smallLetter: insurer.smallLetter,
        successTitle: insurer.successTitle,
        successDetails: insurer.successDetails
      }
    });
  };

  React.useEffect(() => {
    if (updateInsurerResponse.data) {
      toast.success(updateInsurerResponse.data.message);
      props.history.push("/insurers");
    }

    if (updateInsurerResponse.errorMessage) {
      toast.error(updateInsurerResponse.errorMessage);
    }
  }, [
    updateInsurerResponse.data,
    updateInsurerResponse.errorMessage,
    props.history
  ]);

  // Get the details of the insurer to update.
  React.useEffect(() => {
    getInsurerRequest({ pathParams: { insurerID } });
  }, [getInsurerRequest, insurerID]);

  // Retrieved insurer details / set default values.
  React.useEffect(() => {
    if (getInsurerResponse.errorMessage) {
      toast.error(getInsurerResponse.errorMessage);
    }

    if (getInsurerResponse.data) {
      setDefaultValues({
        ...getInsurerResponse.data,
        logoUrl: Buffer.from(getInsurerResponse.data.base64LogoUrl, "base64").toString("utf-8"),
        redirectUrl: Buffer.from(getInsurerResponse.data.base64RedirectUrl, "base64").toString("utf-8"),
        callbackUrl: Buffer.from(getInsurerResponse.data.base64CallbackUrl, "base64").toString("utf-8")
      });
    }
  }, [getInsurerResponse]);

  React.useEffect(() => {
    if (updateInsurerResponse.data) {
      toast.success("Successfully updated insurance provider!");
      props.history.push("/insurers");
    }

    if (updateInsurerResponse.errorMessage) {
      toast.error(updateInsurerResponse.errorMessage);
    }
  }, [
    updateInsurerResponse.data,
    updateInsurerResponse.errorMessage,
    props.history
  ]);

  return (
    <Layout>
      <PageHeader title="Edit insurance provider" backLink={() => props.history.goBack()} />
      <Box>
        {defaultValues && !getInsurerResponse.loading ? (
          <InsurerForm
            isLoading={updateInsurerResponse.loading}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
          />
        ) : <LoadingSpinner />} 
      </Box>
    </Layout>
  );
};
