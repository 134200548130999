import React from "react";

import { Table } from "src/components/shared/table";
import {
  Column, Filters, SortingRule 
} from "react-table";
import { useApiRequest } from "src/utils/api";

import { Layout } from "src/components/shared/layout/Layout";
import { PageHeader, Card } from "src/components/shared/layout";
import { PageProps } from "src/pages/Router";
import { toast } from "react-toastify";
import { UserDTO, OrderByObject } from "src/utils/api";
import * as _ from "lodash";
import * as datefns from "date-fns";

type AdminUsersTable = Pick<UserDTO, "businessName" | "dateCreated" | "dateLastActivity" | "id" | "emailAddress"| "firstName" | "lastName" | "roles">;

const queryFiltersToString = (filters: Filters<AdminUsersTable>): string => {
  return filters.filter(filter => !!filter.value).map(filter => {
    let value = filter.value;

    // User LIKE operator.
    if (filter.id === "firstName" || filter.id === "lastName" || filter.id === "emailAddress") {
      value = `~${value}`;
    } else {
      value = `:${value}`;
    }

    return `${filter.id}${value}`
    ;
  }).join(",");
};

const columns: Column<AdminUsersTable>[] = [
  {
    Header: "First name",
    accessor: "firstName"
  },
  {
    Header: "Last name",
    accessor: "lastName"
  },
  {
    Header: "Email",
    accessor: "emailAddress"
  },
  {
    Header: "Joined",
    accessor: "dateCreated",
    disableFilters: true,
    Cell: ({ value }) => value ? datefns.format(value, "dd/MM/yyyy HH:mm") : ""
  },
  {
    Header: "Last Active",
    accessor: "dateLastActivity",
    disableFilters: true,
    Cell: ({ value }) => value ? datefns.format(value, "dd/MM/yyyy HH:mm") : ""
  },
  {
    Header: "Member type",
    accessor: "roles",
    disableFilters: true,
    Cell: ({ row }) => _.reduce(
      row.original.roles, (
        prev, current, index
      ) => {
        prev += `${index > 0 ? ", " : ""} ${current}`;

        return prev;
      }, ""
    )
  }

];

const hiddenColumns = ["id"];

const AdminUsersPage: React.FC<PageProps<{}>> = () => {
  const [data, setData] = React.useState<UserDTO[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [adminUsersResponse, adminUsersRequest] = useApiRequest("USERS:getAdminUsers");
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(({
    pageSize, pageIndex, sortBy, tableFilters 
  }: {
    pageSize: number;
    pageIndex: number;
    tableFilters?: Filters<AdminUsersTable>;
    sortBy?: Array<SortingRule<AdminUsersTable>>;
  }) => {
    // Setup order with default, if included:
    let order: OrderByObject<UserDTO> = { dateCreated: "ASC" };
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Override with sortBy from the table, if included:
    if (sortBy && sortBy[0]) {
      order = { [sortBy[0].id as keyof AdminUsersTable]: sortBy[0].desc ? "DESC" : "ASC" };
    }

    if (fetchId === fetchIdRef.current) {
      const filters: Partial<AdminUsersTable> = {};

      tableFilters?.map(filter => {
        const id = filter.id as keyof AdminUsersTable;

        filters[id] = filter.value;
      });
      // Set the loading state
      setLoading(true);
      
      adminUsersRequest({
        params: {
          query: tableFilters && tableFilters.length ? queryFiltersToString(tableFilters) : "",
          page: pageIndex + 1,
          pageSize,
          sortBy: Object.keys(order).map(sortField => `${sortField}:${order[sortField as keyof UserDTO]}`).join(",")
        }
      });
    }
  }, [adminUsersRequest]);

  React.useEffect(() => {
    if (adminUsersResponse.data && adminUsersResponse.data.items) {
      setData(adminUsersResponse.data.items);
      setPageCount(Math.ceil(adminUsersResponse.data.total / adminUsersResponse.data.pageSize));
      setLoading(false);
    }

    if (adminUsersResponse.errorMessage) {
      toast.error(adminUsersResponse.errorMessage);
    }
  }, [adminUsersResponse]);

  return (
    <Layout>
      <PageHeader title="All admin users" />
      <Card>
        <Table<AdminUsersTable>
          name={"Admin users table"}
          title={`${adminUsersResponse.data?.total || 0} Admin Users`}
          columns={columns}
          data={data}
          loading={loading}
          fetchData={fetchData}
          defaultHiddenColumns={hiddenColumns}
          pageCount={pageCount}
          usePagination={true}
          useFilters={true}
          useHideColumns={true}
          useResizeColumns
          useSortBy
          featuredFilter="emailAddress"
        />
      </Card>
    </Layout>
  );
};

export default AdminUsersPage;
