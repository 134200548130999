import { PickParams } from "../api";
import { ApiRouteDefinition } from "../useApiRequest";

export const LocationRoutes = {
  /* Fetch location */
  "LOCATIONS:getLocation": {
    path: "/locations/:locationUuid",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<LocationDTO, null, PickParams<"locationUuid">>,

  /* Get address from CraftyClicks services by postcode */
  "LOCATIONS:getAddress": {
    path: "/locations/addressByPostcode",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<BusinessLocationDTO, BusinessLocationRequest>,

  /* Fetch drop-off locations */
  "LOCATIONS:getUserLocations": {
    path: "/users/:userUuid/locations",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<LocationDTO[], null, PickParams<"userUuid">>,

  /* New location */
  "LOCATIONS:createLocation": {
    path: "/users/:userUuid/locations",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
    LocationDTO,
    LocationRequest,
    PickParams<"userUuid">
  >
};

export enum LocationAdminFlagsEnum {
  REPORTED = "REPORTED",
  BANNED = "BANNED",
  OK = "OK",
  FLAGGED = "FLAGGED",
  ABUSE = "ABUSE",
  NOTACTIVATED = "NOT_ACTIVATED",
  INVITED = "INVITED"
}

export interface LocationDTO {
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  id?: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  postCode?: string;
  region?: string;
  type: LocationTypeEnum;
}

export enum LocationTypeEnum {
  PRIMARY_ADDRESS = "PRIMARY_ADDRESS",
  USER = "USER",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  ITEM = "ITEM",
  UNKNOWN = "UNKNOWN"
}

/**
 * New location
 * @export
 * @interface LocationRequest
 */
export interface LocationRequest {
  address1: string;
  address2?: string;
  city: string;
  country?: string;
  postCode: string;
  region?: string;
}

export interface Geocode {
  latitude: number;
  longitude: number;
}

export interface BusinessLocationDTO {
  address?: Array<LocationDTO>;
  postcode?: string;
}

export interface BusinessLocationRequest {
  postcode?: string;
}