import React from "react";
import { toast } from "react-toastify";
import { Box, Menu } from "grommet";
import { PageHeader } from "src/components/shared/layout";
import { Layout } from "src/components/shared/layout/Layout";
import styled, { css } from "styled-components";
import formatDate from "date-fns/format";

import { PageProps } from "src/pages/Router";
import { useApiRequest, InsurerDTO } from "src/utils/api";

import { LoadingSpinner } from "src/components/shared/loading-spinner";
import {
  InfoPanel, InfoColumn, InfoBox
} from "src/components/shared/info";

interface ViewPublicInsurerPageParams {
  insurerID: string;
}

export const ViewInsurerPage: React.FC<PageProps<Record<string, unknown>, ViewPublicInsurerPageParams>> = props => {
  const [insurer, setInsurer] = React.useState<InsurerDTO>();
  const [loading, setLoading] = React.useState(true);
  const [getInsurerResponse, getInsurerRequest] = useApiRequest("INSURERS:getInsurer");
  const insurerID = React.useMemo(() => props.match?.params.insurerID || "", [props.match]);

  // Request the insurer by id based off path params
  React.useEffect(() => {
    if (!insurer) {
      getInsurerRequest({ pathParams: { insurerID } });
    }
  }, [
    getInsurerRequest,
    insurer,
    insurerID
  ]);

  // Detect the response for get insurer by id
  React.useEffect(() => {
    if (getInsurerResponse.data) {
      setInsurer(getInsurerResponse.data);
      setLoading(false);
    }

    if (getInsurerResponse.errorMessage) {
      toast.error(getInsurerResponse.errorMessage);
    }
  }, [
    getInsurerResponse,
    props.history,
    props.match
  ]);

  return (
    <Layout>
      <PageHeader 
        backLink={() => props.history.goBack()}
      />
      {loading ? <LoadingSpinner /> :
        <Box direction="column">
          {insurer && (
            <>
              <Box width={{ max:"15rem" }}>
                <StyledMenu
                  dropBackground="Neutral500"
                  color="white"
                  justifyContent="between"
                  dropProps={{
                    align: {
                      top: "bottom",
                      right: "right"
                    }
                  }}
                  items={[
                    {
                      label: "Edit insurance provider",
                      onClick: () => props.history.push(`/insurers/${insurerID}/edit`)

                    }
                  ]}
                  label="Actions"
                />
              </Box>
              <Box width="100%">
                <InfoPanel label={"Insurance provider details"}>
                  <InfoColumn>
                    <InfoBox label="Enabled" value={insurer.enabled ? "Yes" : "No"} />
                    <InfoBox label="Name" value={insurer.providerName} />
                    <InfoBox label="Logo url" value={Buffer.from(insurer.base64LogoUrl, "base64").toString("utf-8") }/>
                    <InfoBox label="Date created" value={insurer.dateCreated ? formatDate(new Date(insurer.dateCreated), "dd/mm/YYYY HH:mm:ss") : "-"} />
                  </InfoColumn>
                </InfoPanel>
                <InfoPanel label={"Insurance process"}>
                  <InfoColumn>
                    <InfoBox label="Handover screen - Redirect page url" value={Buffer.from(insurer.base64RedirectUrl, "base64").toString("utf-8") } />
                    <InfoBox label="Handover screen - Title" value={insurer.title} />
                    <InfoBox label="Handover screen - Details" value={insurer.insuranceDetails} />
                    <InfoBox label="Handover screen - Small letter" value={insurer.smallLetter} />
                    <InfoBox label="Success screen - Callback url" value={Buffer.from(insurer.base64CallbackUrl, "base64").toString("utf-8") } />
                    <InfoBox label="Success screen - Title" value={insurer.successTitle} />
                    <InfoBox label="Success screen - Details" value={insurer.successDetails} />
                  </InfoColumn>
                </InfoPanel>
              </Box>
            </>
          )}
        </Box>
      }
    </Layout>
  );
};

const StyledMenu = styled(Menu)`
  ${({ theme }) => css`
    background: ${theme.colors.Primary500};
    border-radius: ${theme.borderRadius.large};
  `};
`;
