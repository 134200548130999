import { Select as GrommetSelect, SelectProps } from "grommet";

import styled, { css } from "styled-components";

type CustomSelectProps = SelectProps;

export const Select = styled(GrommetSelect)<CustomSelectProps>`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.bodySmall};
  `}
`;
