import React from "react";
import { Box } from "grommet";
import { toast } from "react-toastify";
import formatDate from "date-fns/format";
import parse from "date-fns/parse";
import { PageHeader } from "src/components/shared/layout";
import { Layout } from "src/components/shared/layout/Layout";

import { PageProps } from "src/pages/Router";
import { useApiRequest, LocationTypeEnum } from "src/utils/api";
import { PublicUserForm, PublicUserFormInput } from "src/components/public-users/PublicUserForm";

export const NewPublicUserPage: React.FC<PageProps<{}>> = props => {
  const [createUser, createUserReq] = useApiRequest("USERS:adminCreatePublicUser");

  // On form submission
  const onSubmit = (user: PublicUserFormInput) => {
    createUserReq({
      data: {
        ...user,
        phone: user.phoneNumber,
        // Convert the date back to yyyy-MM-dd.
        birthdate: user.birthdate ? formatDate(parse(
          user.birthdate, "dd/MM/yyyy", new Date()
        ), "yyyy-MM-dd") : undefined,
        // Convert the location back to DTO format.
        locationDTO: {
          address1: user.locationAddress1 ? user.locationAddress1 : undefined,
          address2: user.locationAddress2 ? user.locationAddress2 : undefined,
          city: user.locationCity ? user.locationCity : undefined,
          postCode: user.locationPostcode ? user.locationPostcode : undefined,
          type: LocationTypeEnum.PRIMARY_ADDRESS
        }
      } 
    });
  };

  React.useEffect(() => {
    if (createUser.data) {
      toast.success("New user was succesfully created!");
      props.history.push("/users/public");
    }

    if (createUser.errorMessage) {
      toast.error(createUser.errorMessage);
    }
  }, [
    createUser.data,
    createUser.errorMessage,
    props.history
  ]);

  return (
    <Layout>
      <PageHeader title="New User" backLink={() => props.history.goBack()} />
      <Box>
        <PublicUserForm
          type="create"
          isLoading={false}
          onSubmit={onSubmit}
        />
      </Box>

    </Layout>
  );
};
