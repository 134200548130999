import React from "react";
import { HeaderProps } from "react-table";
import { $enum } from "ts-enum-util";
import styled from "styled-components";

import { Text } from "src/components/shared/text";
import { ItemCategoryEnum } from "src/utils/api";
import { Select } from "../forms/Select";

export const SelectItemCategoryColumnFilter = Object.assign(<T extends Record<string, unknown>>({ 
  column: {
    filterValue, setFilter, id 
  }, onChange
}: HeaderProps<T> & {
  onChange: () => void;
}): React.ReactElement => {
  const options: {
    label: string;
    value: ItemCategoryEnum | undefined;
}[] = ($enum(ItemCategoryEnum).getKeys()).reduce((
  acc: Array<{label: string; value: ItemCategoryEnum}>, key, index
) => {
  return acc.concat([
    {
      label: key.toLowerCase().charAt(0).toUpperCase() + key.toLowerCase().slice(1),
      value: $enum(ItemCategoryEnum).getValues()[index]
    }
  ]);
}, []);

  const defaultOption = {
    value: undefined,
    label: "All categories" 
  };

  options.unshift(defaultOption);

  return (
    <Select
      value={filterValue ? options.find(option => option.value === filterValue) : defaultOption}
      name={id}
      placeholder="Select category"
      onChange={({ value }) => {
        if (onChange) {
          onChange();
        }

        setFilter(value ? value.value : undefined);
      }}
      options={options}
      labelKey="label"
      valueKey="value"
      // eslint-disable-next-line react/no-children-prop
      children={(option=> <StyledOption>{option.label}</StyledOption>)}
    />
  );
}, { displayName: "Select" });

const StyledOption = styled(Text)`
  width: 100%;
  padding: 1rem;
  display: block;
`;

export default SelectItemCategoryColumnFilter;