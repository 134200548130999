import styled, { css } from "styled-components";
import { FormField } from "grommet";

export const FormRow = styled(FormField)`
${({ theme }) => css`
  width: 100%;

  label {
    margin-left: 0;
    font-weight: ${theme.font.weights.medium};
    font-size: ${theme.font.sizes.bodySmall};
  }

  > div {
    border: none;
  }
  `}
`;

FormRow.displayName = "FormRow";