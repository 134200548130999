import { Box } from "grommet";
import React, { FormEvent, ReactElement } from "react";
import { TableInstance, Filters } from "react-table";
import { Select } from "src/components/forms/Select";

type FeaturedFilterProps<T extends Record<string, unknown>> = {
  instance: TableInstance<T>;
  featuredFilter?: keyof T;
  setTableFilters: (data: Filters<T>) => void;
};

export function FeaturedFilter<T extends Record<string, unknown>>({
  instance, featuredFilter, setTableFilters 
}: FeaturedFilterProps<T>): ReactElement {
  const { allColumns } = instance;
  
  const onSubmit = React.useCallback((e?: FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }
    
    const filterData: Filters<T> = [];
    
    allColumns.map(col => {
      filterData.push({
        id: col.id,
        value: col.filterValue
      });
    });
    setTableFilters(filterData);
  }, [allColumns, setTableFilters]);

  const onSelectChange = React.useCallback((id: number, value: unknown) => {
    const filterData: Filters<T> = [];
    
    // Change the value of the column filter
    // to the value passed from the select.
    allColumns.map(col => {
      filterData.push({
        id: col.id,
        value: col.id === id ? value : col.filterValue
      });
    });
    setTableFilters(filterData);
  }, [allColumns, setTableFilters]);

  const featuredColumn = allColumns.find(it => it.canFilter && it.id === featuredFilter);
  // Trigger filtering in onChange if field type is Select (infered by displayName).
  let FilterComponent: React.ReactElement<unknown, typeof Select> | React.ReactElement | null = featuredColumn ? featuredColumn.render("Filter") as React.ReactElement : null;

  if ((FilterComponent as React.ReactElement<unknown, typeof Select>)?.type?.displayName?.includes("Select")) {
    FilterComponent = React.cloneElement((FilterComponent as React.ReactElement<{onChange: (id: number, value: unknown) => void}, typeof Select>), { onChange: onSelectChange });
  }

  return (
    <Box>
      {featuredColumn && (
        <Box 
          pad="small" 
        >
          <form onSubmit={onSubmit}>
            <div>
              <div key={featuredColumn.id}>
                {FilterComponent}
              </div>
            </div>
          </form>
        </Box>
      )}
    </Box>

  );
}
