import {
  BasePaginatedQuery,
  InsuranceSummaryDTO,
  PickParams,
  StringResponse,
  OrderBy
} from "../api";
import { ApiRouteDefinition } from "../useApiRequest";
import { LocationDTO } from "./locations.api";
import { PartnerDTO, PartnersMapRequest } from "./business-partners.api";

import { RoleEnum } from "./authentication.api";
import {
  DeviceTypeEnum, AppTypeEnum, SocialTypeEnum, ItemCategoryDTO
} from "../shared-types";

export const UserRoutes = {
  /** Update user info: UPDATE USER */
  "USERS:updateUserInfo": {
    path: "/users/:userUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    UserUpdateRequest,
    PickParams<"userUuid">
  >,

  /** Delete User */
  "USERS:deleteUser": {
    path: "/users/:userUuid",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<StringResponse, null, PickParams<"userUuid">>,

  /** Get all users in user's business */
  "USERS:getUsersByBusiness": {
    path: "/users/:userUuid/business/user",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PaginatedCollectionResponseUserDTO,
    null,
    PickParams<"userUuid">,
    BusinessUsersQuery
  >,

  /** Allows a user with the COMPANY_ADMIN role to invite users to join their business */
  "USERS:inviteUserToBusiness": {
    path: "/users/:userUuid/business/user",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
    BusinessUserInvitedResponse,
    BusinessUserInvite,
    PickParams<"userUuid">
  >,

  /** Returns a summary of the status of the user's insurance */
  "USERS:getItemInsuranceSummery": {
    path: "/users/:userUuid/items/insurance/summary",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<InsuranceSummaryDTO, null, PickParams<"userUuid">>,

  /** Update roles for a user.*/
  "USERS:updateUserRole": {
    path: "/users/:userUuid/roles",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    RoleUpdateRequest,
    PickParams<"userUuid">
  >,

  /** Send verification email to user. This will happen automatically when they sign up but this endpoint can be used to resend the email if required. */
  "USERS:resendVerificationEmail": {
    path: "/users/:userUuid/verification",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<StringResponse, null, PickParams<"userUuid">>,

  /**  Check to see whether the email address belongs to a user who has been invited to join a business */
  "USERS:checkEmailInBusiness": {
    path: "/users/business/invitation",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    BusinessUserInvitedResponse,
    null,
    null,
    { email: string }
  >,

  /** Update user image: UPDATE USER */
  "USERS:updateUserImage": {
    path: "/users/image/:userUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    FormData,
    PickParams<"userUuid">
  >,

  /** Get partners on map by coordinates */
  "USERS:getPartners": {
    path: "/users/partners",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<PartnerDTO[], PartnersMapRequest>,

  /** Request password reset email (unauthorised) */
  "USERS:passwordResetRequest": {
    path: "/users/password",
    authenticate: false,
    method: "POST"
  } as ApiRouteDefinition<StringResponse, PasswordResetRequest>,

  /** Complete password reset (unauthorised) */
  "USERS:resetPassword": {
    path: "/users/password/:tokenValue",
    authenticate: false,
    method: "POST"
  } as ApiRouteDefinition<
    StringResponse,
    ChangePasswordBody,
    PickParams<"tokenValue">
  >,

  /** Change password for logged in user (authorised) */
  "USERS:changePassword": {
    path: "/users/password/:userUuid/change",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
    StringResponse,
    PasswordChangeRequest,
    PickParams<"userUuid">
  >,

  /** Fetch user preregistered items by categories */
  "USERS:getPreregisteredByCategory": {
    path: "/users/preregistered/:userUuid/categories",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<Array<ItemCategoryDTO>, null, PickParams<"userUuid">>,

  "USERS:signupRegular": {
    path: "/users/regular",
    authenticate: false,
    method: "POST"
  } as ApiRouteDefinition<StringResponse, RegularUserRequest, null>,

  /** Get free user slots */
  "USERS:getFreeUserSlots": {
    path: "/users/slots/:userUuid",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<SlotsRequestResponse, null, PickParams<"userUuid">>,

  /** Update user slots number */
  "USERS:updateUserSlots": {
    path: "/users/slots/:userUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    SlotsRequestResponse,
    SlotsRequestResponse,
    PickParams<"userUuid">
  >,

  /** Get user info */
  "USERS:getUserInfo": {
    path: "/users/users/:userUuid",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    UserDTO,
    null,
    PickParams<"userUuid">
  >,

  /* Return all Found staff members who have full admin privileges. */
  "USERS:getAdminUsers": {
    path: "/users/users/admin",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PaginatedCollectionResponseUserDTO,
    null,
    null,
    BusinessUsersQuery
  >,

  /* Get all users */
  "USERS:getAllBusinessUsers": {
    path: "/users/users/business",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PaginatedCollectionResponseUserDTO,
    null,
    null,
    BusinessUsersQuery
  >,

  /* Get all public users */
  "USERS:getAllPublicUsers": {
    path: "/users/users/public",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PaginatedCollectionResponseUserDTO,
    null,
    null,
    BusinessUsersQuery
  >,

  /* Public endpoint to create a public user */
  "USERS:createPublicUser": {
    path: "/users/users/public",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
      StringResponse,
      CreatePublicUserInput,
      null,
      null
    >,

  /* Admin endpoint to create a public user */
  "USERS:adminCreatePublicUser": {
    path: "/users/users/admin/public",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
      StringResponse,
      CreatePublicUserInput,
      null,
      null
    >
};

export interface BusinessUsersQuery extends BasePaginatedQuery {
  query?: string;
  sortBy?: OrderBy;
}

export interface ChangePasswordBody {
  confirmPassword: string;
  password: string;
}

export interface UserUpdateRequest {
  acceptMarketing?: boolean;
  address?: string;
  birthdate?: string | null;
  businessName?: string;
  businessType?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  locationDTO?: LocationDTO;
  phoneNumber?: string;
  website?: string;
  workingHours?: Array<WorkingHours>;
}

export interface UserDTO {
  acceptMarketing?: boolean;
  appType?: AppTypeEnum;
  birthdate?: string;
  businessName?: string;
  businessType?: string;
  dateCreated?: number;
  dateLastActivity?: number;
  emailAddress?: string;
  firstName?: string;
  id?: string;
  itemsLost?: number;
  itemsRegistered?: number;
  itemsReturned?: number;
  lastName?: string;
  locationDTO?: LocationDTO;
  phoneNumber?: string;
  roles?: Array<RoleEnum>;
  type?: SocialTypeEnum;
  userProfilePicture?: string;
  website?: string;
  workingHours?: Array<WorkingHours>;
  origin?: UserOriginEnum;
  originInfo?: string;
  posBusinessPartnerUUID?: string;
}

export enum UserOriginEnum {
  POS = "POS",
  SUPPORT = "SUPPORT",
  DEFAULT = "DEFAULT"
}
export interface CreatePublicUserInput {
  email: string;
  firstName: string;
  lastName: string;
  origin: UserOriginEnum;
  originInfo: string;
  phone?: string;
  posBusinessPartnerUUID?: string;
  birthdate?: string;
  locationDTO?: LocationDTO;
}

export interface Flags {
  flags?: number;
}

export interface PaginatedCollectionResponseUserDTO {
  items: Array<UserDTO>;
  itemsCount: number;
  page: number;
  pageSize: number;
  total: number;
}

export interface PublicUserInfo {
  emailAddress?: string;
}

export interface RegularUserRequest {
  acceptMarketing: boolean;
  appType: AppTypeEnum;
  businessName?: string;
  businessType?: string;
  deviceToken: string;
  deviceType: DeviceTypeEnum;
  email: string;
  locationDTO?: LocationDTO;
  name: string;
  organization?: boolean;
  password: string;
  phone?: string;
  website?: string;
  workingHours?: Array<WorkingHours>;
  zipcode?: string;
}

/**
 * Reports whether or not the user has been invited to join a business.
 * @export
 * @interface BusinessUserInvitedResponse
 */
export interface BusinessUserInvitedResponse {
  invited?: boolean;
}

/**
 * Invitation for an employee fof a company  to join Found
 * @export
 * @interface BusinessUserInvite
 */
export interface BusinessUserInvite {
  email?: string;
}

export interface PasswordChangeRequest {
  newPassword?: string;
  newPasswordConfirm?: string;
  newPasswordConfirmMasked?: string;
  newPasswordMasked?: string;
  oldPassword?: string;
  oldPasswordMasked?: string;
}

export interface PasswordResetRequest {
  appType?: AppTypeEnum;
  email?: string;
}

export interface RoleUpdateRequest {
  roles?: Array<RoleEnum>;
}

export interface SlotsRequestResponse {
  slots?: number;
}

export interface WorkingHours {
  closingTime: number;
  day: WorkingHoursDayEnum;
  openingTime: number;
}

export interface SocialUserRequest {
  acceptMarketing: boolean;
  appType: AppTypeEnum;
  business?: string;
  deviceToken: string;
  deviceType: DeviceTypeEnum;
  organization?: boolean;
  socialMediaSecret?: string;
  socialMediaToken: string;
  type: SocialTypeEnum;
  workingHours?: Array<WorkingHours>;
}

export enum WorkingHoursDayEnum {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY"
}