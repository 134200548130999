import React from "react";
import { PublicLayout } from "src/components/shared/layout/PublicLayout";
import { useAuthenticationContext } from "src/utils/authentication/Authentication";
import { PageProps } from "src/pages/Router";
import { Button } from "src/components/shared/button/Button";
import styled from "styled-components";
import { Text } from "src/components/shared/text";

const NotFound: React.FunctionComponent<PageProps<{}>> = ({ history }) => {
  const { authTokenState } = useAuthenticationContext();

  return <PublicLayout>
    <NotFoundWrapper>
      <Text as="p" className="not-found-text">404 - Page Not Found</Text>
      {authTokenState ? <Button onClick={()=> history.push("/items")} label="Back to Dashboard"/> : <Button onClick={()=> history.push("/")} label="Login"/>}
    </NotFoundWrapper>
  </PublicLayout>;
};

const NotFoundWrapper = styled.div`
  text-align: center;

  .not-found-text {
    margin-bottom: 2rem;
  }
`;

export default NotFound;
