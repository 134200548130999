import {
  PickParams,
  ResponseId,
  StringResponse
} from "../api";
import { ApiRouteDefinition } from "../useApiRequest";
import { PublicUserInfo } from "./users.api";
import { PartnerDTO } from "./business-partners.api";
import { ItemCategoryEnum } from "../shared-types";

export const MatchRoutes = {
  /* Create a lost/found match */
  "MATCHES:matches": {
    path: "/matches",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<ResponseId, null, null, CreateMatchQuery>,

  /* Retrieve a lost/found match*/
  "MATCHES:getMatch": {
    path: "/matches/:matchUuid",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<LostFoundMatchDTO, null, PickParams<"matchUuid">>,

  /* Cancel a match */
  "MATCHES:cancelMatch": {
    path: "/matches/:matchUuid/cancelled",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<StringResponse, null, PickParams<"matchUuid">>,

  /* Confirm a match */
  "MATCHES:confirmMatch": {
    path: "/matches/:matchUuid/confirmed",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<StringResponse, null, PickParams<"matchUuid">>
};

export interface CreateMatchQuery {
  "found-uuid": string;
  "lost-uuid": string;
}

export interface FoundItemMatchDTO {
  /**
   *
   * @type {string}
   * @memberof FoundItemMatchDTO
   */
  brand?: string;
  /**
   *
   * @type {string}
   * @memberof FoundItemMatchDTO
   */
  category?: ItemCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof FoundItemMatchDTO
   */
  colour?: string;
  /**
   *
   * @type {string}
   * @memberof FoundItemMatchDTO
   */
  dateFound?: number;
  /**
   *
   * @type {string}
   * @memberof FoundItemMatchDTO
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FoundItemMatchDTO
   */
  images?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof FoundItemMatchDTO
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof FoundItemMatchDTO
   */
  longitude?: number;
  /**
   *
   * @type {string}
   * @memberof FoundItemMatchDTO
   */
  model?: string;
}

export interface LostFoundMatchDTO {
  finderType?: LostFoundMatchDTOFinderTypeEnum;
  foundItem?: FoundItemMatchDTO;
  id?: string;
  lostItemId?: string;
  lostUserId?: string;
  partnerInfo?: PartnerDTO;
  userInfo?: PublicUserInfo;
}

/**
 * @export
 * @enum {string}
 */
export enum LostFoundMatchDTOFinderTypeEnum {
  PUBLIC = "PUBLIC",
  BUSINESS = "BUSINESS",
  SITE = "SITE"
}