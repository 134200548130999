import React from "react";
import styled from "styled-components";

const TableFooter: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { children, ...rest } = props;

  return (
    <StyledTableFooter {...rest}>
      {children}
    </StyledTableFooter>
  );
};

const StyledTableFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
`;

export default TableFooter;