import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const GlobalStyles = createGlobalStyle`
  ${reset};

  * {
    box-sizing: border-box;
  }

  html {
    position: relative;
    font-size: 62.5%;
  }

  body {
    font-family: ${({ theme }) => theme.font.family.secondary}, sans-serif;
    font-weight:  ${({ theme }) => theme.font.weights.regular};
    font-size:  ${({ theme }) => theme.font.sizes.bodyRegular};
    width: 100%;
    min-height: 100vh;
    margin: 0;
    background:  ${({ theme }) => theme.colors.Neutral300};
    color: ${({ theme }) => theme.colors.Neutral900};
    text-size-adjust: 100%;
  }

  .react-datepicker {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: ${({ theme }) => theme.font.sizes.bodySmall};

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
      width: 3.7rem;
      line-height: 3.7rem;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-size: ${({ theme }) => theme.font.sizes.bodySmall};
    }
  }

  
`;
