import {
  NotificationDTO,
  PickParams
} from "../api";
import { ApiRouteDefinition } from "../useApiRequest";

export const NotificationRoutes = {
  /* Retrieve all notifications for user */
  "NOTIFICATIONS:matches": {
    path: "/users/:userUuid/notifications",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<NotificationDTO[], null, PickParams<"userUuid">>
};
