import React from "react";
import styled from "styled-components";
import { Text } from "../text";

export interface InfoBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  value?: React.ReactChild | null;
}

/**
 * Info box
 *
 * Usage: InfoPanel > InfoColumn > InfoBox
 */
export const InfoBox: React.FC<InfoBoxProps> = props => {
  const {
    label, value, ...rest
  } = props;

  return (
    <InfoBoxStyled {...rest}>
      <Label title={label} as="small" family="primary" bold={true}>{label}:</Label>
      <Text as="small" family="primary">{value || "-"}</Text>
    </InfoBoxStyled>
  );
};

const InfoBoxStyled = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1.2rem;

  &:first-child {
    padding-top: 0;
  }
`;

const Label = styled(Text)`
  padding-right: 2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
