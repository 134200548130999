import React from "react";
import styled from "styled-components";

interface TableWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  disabled: boolean;
}

const TableWrapper: React.FC<TableWrapperProps> = props => {
  const {
    className, children, ...rest
  } = props;

  return (
    <StyledTable className={[className, props.disabled ? "disabled" : ""].join(" ")} {...rest}>
      {children}
    </StyledTable>
  );
};

const StyledTable = styled.div`
  padding: 1.5rem;
  width: 100%;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;

export default TableWrapper;