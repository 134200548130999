import React from "react";

import { Table } from "src/components/shared/table";
import {
  Column, Filters, Row, SortingRule
} from "react-table";

import { Button } from "src/components/shared/button";
import { Layout } from "src/components/shared/layout/Layout";
import { PageHeader, Card } from "src/components/shared/layout";
import { PageProps } from "src/pages/Router";
import { toast } from "react-toastify";
import {
  useApiRequest,
  ItemDTO,
  ItemDTOStatusEnum,
  OrderByObject
} from "src/utils/api";
import * as datefns from "date-fns";
import {
  SelectItemCategoryColumnFilter, 
  SelectItemStatusColumnFilter
} from "src/components/items";
import { mapItemStatus } from "src/utils/item";

type ItemsTable = Pick<ItemDTO, "category" | "dateCreated" | "brand" | "model" | "color" | "status" | "id">;

const columns: Column<ItemsTable>[] = [
  {
    Header: "Category",
    accessor: "category",
    Filter: SelectItemCategoryColumnFilter,
    Cell: ({ value }) => value.toLowerCase().charAt(0).toUpperCase() + value.toLowerCase().slice(1)
  },
  {
    Header: "Brand",
    accessor: "brand"
  },
  {
    Header: "Model",
    accessor: "model"
  },
  {
    Header: "Colour",
    accessor: "color"
  },
  {
    Header: "Registered On",
    accessor: "dateCreated",
    disableFilters: true,
    Cell: ({ value }) => value ? datefns.format(value, "dd/MM/yyyy HH:mm") : ""
  },
  {
    accessor: "status",
    Header: "Status",
    Filter: SelectItemStatusColumnFilter,
    filter: "equals",
    Cell: ({ value }) => mapItemStatus(value as ItemDTOStatusEnum)
  }
];

const hiddenColumns = ["id"];

const queryFiltersToString = (filters: Filters<ItemsTable>): string => {
  return filters.filter(filter => filter.value !== undefined).map(filter => {
    let value = filter.value;

    // User LIKE operator.
    if (filter.id === "brand" || filter.id === "model" || filter.id === "colour") {
      value = `~${value}`;
    } else {
      value = `:${value}`;
    }

    return `${filter.id}${value}`
    ;
  }).join(",");
};

const ItemsPage: React.FC<PageProps<{}>> = props => {
  const [data, setData] = React.useState<ItemDTO[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [itemsResponse, itemsRequest] = useApiRequest("ITEMS:getAllItemsByFilter");
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(({
    pageSize, pageIndex, tableFilters, sortBy
  }: {
    pageSize: number;
    pageIndex: number;
    tableFilters?: Filters<ItemsTable>;
    sortBy?: Array<SortingRule<ItemsTable>>;
  }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Setup order with default, if included:
    let order: OrderByObject<ItemDTO> = { dateCreated: "ASC" };

    // Override with sortBy from the table, if included:
    if (sortBy && sortBy[0]) {
      order = { [sortBy[0].id as keyof ItemsTable]: sortBy[0].desc ? "DESC" : "ASC" };
    }

    if (fetchId === fetchIdRef.current) {
      const filters: Partial<ItemsTable> = {};

      tableFilters?.map(filter => {
        const id = filter.id as keyof ItemsTable;

        filters[id] = filter.value;
      });
      // Set the loading state
      setLoading(true);

      itemsRequest({
        params: {
          sortBy: Object.keys(order).map(sortField => `${sortField}:${order[sortField as keyof ItemDTO]}`).join(","),
          query: tableFilters && tableFilters.length ? queryFiltersToString(tableFilters) : "",
          page: pageIndex + 1,
          pageSize
        }
      });
    }
  }, [itemsRequest]);

  React.useEffect(() => {
    if (itemsResponse.data && itemsResponse.data.items) {
      setData(itemsResponse.data.items);
      setPageCount(Math.ceil(itemsResponse.data.total / itemsResponse.data.pageSize));
      setLoading(false);
    }

    if (itemsResponse.errorMessage) {
      toast.error(itemsResponse.errorMessage);
    }
  }, [itemsResponse]);

  const handleOnClick = React.useCallback((row: Row<ItemsTable>) => {
    props.history.push(`/items/${row.original.id}`);
  }, [props.history]);

  return (
    <Layout>
      <PageHeader title="Items" callToAction={<Button label="New" onClick={() => props.history.push("/items/new")} />} />
      <Card>
        <Table<ItemsTable>
          name={"itemsTable"}
          title={`${itemsResponse.data?.total || 0} Items`}
          columns={columns}
          data={data}
          loading={loading}
          onClick={handleOnClick}
          fetchData={fetchData}
          featuredFilter="status"
          defaultHiddenColumns={hiddenColumns}
          pageCount={pageCount}
          usePagination={true}
          useFilters={true}
          useHideColumns={true}
          useResizeColumns
          useSortBy
        />
      </Card>
    </Layout>
  );
};

export default ItemsPage;
