import React from "react";
import styled from "styled-components";

/**
 * Info layout column
 *
 * Usage: InfoLayoutWrapper > InfoLayoutColumn
 */
export const InfoLayoutColumn: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { children, ...rest } = props;

  return (
    <InfoLayoutColumnStyled {...rest}>
      {children}
    </InfoLayoutColumnStyled>
  );
};

const InfoLayoutColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
