import React from "react";

import * as Sentry from "@sentry/browser";
import Amplify from "aws-amplify";
import { Helmet, HelmetProvider } from "react-helmet-async";

import AppRouter from "./pages/Router";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://7eb4c3e85b0742238163e3114a7d3edc@o406647.ingest.sentry.io/5274508",
    environment: process.env.NODE_ENV
  });
}

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_ID_POOL,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT,
    authenticationFlowType: "USER_PASSWORD_AUTH"
  }
});

export const App: React.FC = () => {
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Cabin:wght@400;600&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <AppRouter />
      </HelmetProvider>
    </div>
  );
};
