import styled from "styled-components";
import React from "react";
import { NavLink } from "react-router-dom";
import Text from "../text/Text";
import { Logout } from "grommet-icons";
import { Button } from "../button/Button";
import { useLogout } from "src/utils/authentication";
import useRouter from "use-react-router";

export const Layout: React.FC = props => {
  const { history } = useRouter();
  const logout = useLogout(() => history.push("/"));

  const pages = [
    {
      link: "/items",
      name: "Items"
    },
    {
      link: "/users/public",
      name: "Public Users"
    },
    {
      link: "/users/business",
      name: "Business Partners"
    },
    {
      link: "/users/admin",
      name: "Admin users"
    },
    {
      link: "/insurers",
      name: "Insurance providers"
    }
  ];

  return (
    <Wrapper>
      <NavWrapper>
        <Header>
          <img src="/logo.png" />
        </Header>
        <Navigation>
          {pages.map(page => {
            return (
              <StyledNavLink activeClassName="active" key={page.link} to={page.link}>
                <Text as="small">{page.name}</Text>
              </StyledNavLink>
            );
          })}
        </Navigation>
        <LogoutWrapper>
          <Button plain={true} label="" icon={<Logout />} onClick={logout} />
        </LogoutWrapper>
      </NavWrapper>
      <PageWrapper>
        {props.children}
      </PageWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const LogoutWrapper = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: 2rem;
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.Neutral400};
  width: 14rem;
  align-items: center;
  text-align: center;

  @media (min-width: 1024px) {
    width: 18rem;
  }

  @media (min-width: 1920px) {
    width: 25rem;
  }
`;

const Header = styled.header`
  margin: 4rem 0;

  img {
    width: 50%;
  }
`;

const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const StyledNavLink = styled(NavLink)`
  padding: 1rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.Neutral100};
  border-top: ${({ theme }) => theme.colors.Neutral100} 1px solid;
    &.active {
      background: ${({ theme }) => theme.colors.Neutral300};
    }
`;

const PageWrapper = styled.main`
  position: relative;
  width: calc(100vw - 14rem);
  min-height: 100vh;
  padding: 2.2rem 4%;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.Neutral200};

  @media (min-width: 768px) {
    padding-left: 2.8rem;
    padding-right: 2.8rem;
  }

  @media (min-width: 1024px) {
    width: calc(100vw - 18rem);
  }

  @media (min-width: 1920px) {
    width: calc(100vw - 25rem);
  }
`;