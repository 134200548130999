import React from "react";
import styled from "styled-components";
import { TableRowProps } from "react-table";

interface TableBodyRowProps extends React.HTMLAttributes<HTMLDivElement>, TableRowProps {}

const TableBodyRow: React.FC<TableBodyRowProps> = props => {
  const { children, ...rest } = props;

  return (
    <StyledTableBodyRow {...rest}>
      {children}
    </StyledTableBodyRow>
  );
};

const StyledTableBodyRow = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
`;

export default TableBodyRow;