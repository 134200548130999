import React from "react";
import styled, { css } from "styled-components";
import { Text } from "src/components/shared/text";

interface InfoPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
}

/**
 * Info panel
 *
 * Usage: InfoPanel > InfoColumn > InfoBox
 */
export const InfoPanel: React.FC<InfoPanelProps> = props => {
  const { children, ...rest } = props;

  return (
    <InfoPanelStyled {...rest}>
      <StyledLabel as="h4">
        {props.label}
      </StyledLabel>
      {children}
    </InfoPanelStyled>
  );
};

const StyledLabel = styled(Text)`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.global?.control?.border?.color};
  `};
`;

const InfoPanelStyled = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.global?.control?.border?.color};
    display: flex;
    flex-direction: column;
    background: ${theme.colors.Neutral100};
    border-radius: ${theme.borderRadius.medium};
    padding: 2rem;
    margin-top: 2rem;
  `};
`;
