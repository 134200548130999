export interface AvailableParams {
  insurerID: string;
  userUuid: string;
  itemUuid: string;
  fileUuid: string;
  locationUuid: string;
  matchUuid: string;
  tokenValue: string;
  insuranceUuid: string;
}

export type PickParams<P extends keyof AvailableParams> = Pick<
  AvailableParams,
  P
>;

export interface BasePaginatedQuery {
  page: number;
  pageSize: number;
}

export type OrderByObject<TAttributes> = Partial<Record<keyof TAttributes, "ASC" | "DESC">>;
export type OrderBy = string;

export interface StringResponse {
  message: string;
}

/**
 *
 * @export
 * @interface ImageURLResponse
 */
export interface ImageURLResponse {
  /**
   *
   * @type {string}
   * @memberof ImageURLResponse
   */
  url?: string;
}
/**
 *
 * @export
 * @interface InsuranceSummaryDTO
 */
export interface InsuranceSummaryDTO {
  /**
   *
   * @type {number}
   * @memberof InsuranceSummaryDTO
   */
  insuredPercent?: number;
  /**
   *
   * @type {number}
   * @memberof InsuranceSummaryDTO
   */
  insuredTotal?: number;
  /**
   *
   * @type {number}
   * @memberof InsuranceSummaryDTO
   */
  totalValue?: number;
  /**
   *
   * @type {number}
   * @memberof InsuranceSummaryDTO
   */
  uninsuredTotal?: number;
}
/**
 *
 * @export
 * @interface Item
 */

/**
 *
 * @export
 * @interface JsonNode
 */
export interface JsonNode {
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  array?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  bigDecimal?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  bigInteger?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  binary?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  _boolean?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  containerNode?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  _double?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  _float?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  floatingPointNumber?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  _int?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  integralNumber?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  _long?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  missingNode?: boolean;
  /**
   *
   * @type {string}
   * @memberof JsonNode
   */
  nodeType?: JsonNodeNodeTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  _null?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  number?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  object?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  pojo?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  _short?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  textual?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JsonNode
   */
  valueNode?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum JsonNodeNodeTypeEnum {
  ARRAY = "ARRAY",
  BINARY = "BINARY",
  BOOLEAN = "BOOLEAN",
  MISSING = "MISSING",
  Null = "null",
  NUMBER = "NUMBER",
  OBJECT = "OBJECT",
  POJO = "POJO",
  STRING = "STRING"
}

export interface ModelAndView {
  /**
   *
   * @type {boolean}
   * @memberof ModelAndView
   */
  empty?: boolean;
  /**
   *
   * @type {object}
   * @memberof ModelAndView
   */
  model?: object;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof ModelAndView
   */
  modelMap?: { [key: string]: object };
  /**
   *
   * @type {boolean}
   * @memberof ModelAndView
   */
  reference?: boolean;
  /**
   *
   * @type {string}
   * @memberof ModelAndView
   */
  status?: ModelAndViewStatusEnum;
  /**
   *
   * @type {View}
   * @memberof ModelAndView
   */
  view?: View;
  /**
   *
   * @type {string}
   * @memberof ModelAndView
   */
  viewName?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ModelAndViewStatusEnum {
  _100 = "100",
  _101 = "101",
  _102 = "102",
  _103 = "103",
  _200 = "200",
  _201 = "201",
  _202 = "202",
  _203 = "203",
  _204 = "204",
  _205 = "205",
  _206 = "206",
  _207 = "207",
  _208 = "208",
  _226 = "226",
  _300 = "300",
  _301 = "301",
  _302 = "302",
  _303 = "303",
  _304 = "304",
  _305 = "305",
  _307 = "307",
  _308 = "308",
  _400 = "400",
  _401 = "401",
  _402 = "402",
  _403 = "403",
  _404 = "404",
  _405 = "405",
  _406 = "406",
  _407 = "407",
  _408 = "408",
  _409 = "409",
  _410 = "410",
  _411 = "411",
  _412 = "412",
  _413 = "413",
  _414 = "414",
  _415 = "415",
  _416 = "416",
  _417 = "417",
  _418 = "418",
  _419 = "419",
  _420 = "420",
  _421 = "421",
  _422 = "422",
  _423 = "423",
  _424 = "424",
  _426 = "426",
  _428 = "428",
  _429 = "429",
  _431 = "431",
  _451 = "451",
  _500 = "500",
  _501 = "501",
  _502 = "502",
  _503 = "503",
  _504 = "504",
  _505 = "505",
  _506 = "506",
  _507 = "507",
  _508 = "508",
  _509 = "509",
  _510 = "510",
  _511 = "511"
}

export enum NotificationTypeEnum {
  MATCH = "LOST_ITEM_MATCH",
  INFO = "INFO"
}

/**
 *
 * @export
 * @interface NotificationDataDTO
 */
export interface NotificationDataDTO {
  /**
   *
   * @type {NotificationTypeEnum}
   * @memberof NotificationDataDTO
   */
  type: NotificationTypeEnum;
  /**
   *
   * @type {string}
   * @memberof NotificationDataDTO
   */
  matchID?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationDataDTO
   */
  text?: string;
}

/**
 *
 * @export
 * @interface NotificationDTO
 */
export interface NotificationDTO {
  /**
   *
   * @type {NotificationDataDTO}
   * @memberof NotificationDTO
   */
  data?: NotificationDataDTO;
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  date?: number;
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  userId?: string;
}

export interface ObjectId {
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  counter?: number;
  /**
   *
   * @type {string}
   * @memberof ObjectId
   */
  date?: number;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  machineIdentifier?: number;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  processIdentifier?: number;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  time?: number;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  timeSecond?: number;
  /**
   *
   * @type {number}
   * @memberof ObjectId
   */
  timestamp?: number;
}

export interface ResponseId {
  /**
   *
   * @type {string}
   * @memberof ResponseId
   */
  id?: string;
}

export interface RestAPIError {
  /**
   *
   * @type {string}
   * @memberof RestAPIError
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof RestAPIError
   */
  details?: string;
  /**
   *
   * @type {string}
   * @memberof RestAPIError
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof RestAPIError
   */
  type?: RestAPIErrorTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RestAPIErrorTypeEnum {
  AUTHENTICATION = "AUTHENTICATION",
  AUTHORIZATION = "AUTHORIZATION",
  VALIDATION = "VALIDATION",
  PERSISTENCE = "PERSISTENCE",
  BUSINESS = "BUSINESS",
  GENERAL = "GENERAL"
}

export interface View {
  /**
   *
   * @type {string}
   * @memberof View
   */
  contentType?: string;
}
