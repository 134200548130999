import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Button } from "src/components/shared/button";
import {
  CheckBoxInput,
  FormError,
  FormRow,
  TextInput,
  InputTextArea,
  FormGroup
} from "src/components/forms";
import { InsurerDTO } from "src/utils/api";
import styled from "styled-components";
import { yupResolver } from "@hookform/resolvers";

export type InsurerFormInput = Pick<
  InsurerDTO,
  "enabled" |
  "insuranceDetails" |
  "providerName" | 
  "title" | 
  "smallLetter" |
  "successDetails" |
  "successTitle"
> & {
  logoUrl: InsurerDTO["base64LogoUrl"];
  redirectUrl: InsurerDTO["base64RedirectUrl"];
  callbackUrl: InsurerDTO["base64CallbackUrl"];
};

interface InsurerFormProps {
  isLoading: boolean;
  defaultValues?: Partial<InsurerFormInput>;
  onSubmit: (data: InsurerFormInput) => void;
}

const schema = yup.object<InsurerFormInput>().shape({
  providerName: yup.string().max(64).required(),
  enabled: yup.boolean().required(),
  title: yup.string().max(64).required(),
  insuranceDetails: yup.string().max(256).required(),
  smallLetter: yup.string().max(128).required(),
  successTitle: yup.string().max(64).required(),
  successDetails: yup.string().max(256).required(),
  redirectUrl: yup.string().required(),
  callbackUrl: yup.string().required(),
  logoUrl: yup.string().required()
});

/**
 * Insurer form
 */
export const InsurerForm: React.FC<InsurerFormProps> = ({
  defaultValues, onSubmit, isLoading
}) => {
  const {
    register, handleSubmit, errors, setValue, watch
  } = useForm<InsurerFormInput>({
    defaultValues: { ...defaultValues } as InsurerFormInput,
    resolver: yupResolver(schema)
  });

  const onFormSubmit = React.useCallback((insurer: InsurerFormInput) => {
    onSubmit(insurer as InsurerFormInput);
  }, [onSubmit]);

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <FormGroup groupLabel="Insurance provider details">
        <DetailsRow>
          <FormRow style={{ marginTop: "1rem" }}>
            <CheckBoxInput
              name="enabled"
              label="Enabled"
              checked={watch("enabled")}
              onChange={() => setValue("status", !watch("status"))}
              ref={register()}
            />
            {<FormError message={errors.enabled?.message} />}
          </FormRow>
        </DetailsRow>
        <DetailsRow>
          <FormRow label="Provider name">
            <TextInput
              name="providerName"
              ref={register()}
            />
            {<FormError message={errors.providerName?.message} />}
          </FormRow>
        </DetailsRow>
        <DetailsRow>
          <FormRow label="Logo url">
            <TextInput
              name="logoUrl"
              ref={register()}
            />
            {<FormError message={errors.logoUrl?.message} />}
          </FormRow>
        </DetailsRow>
      </FormGroup>
      <FormGroup groupLabel="Insurance process">
        <DetailsRow>
          <FormRow label="Handover screen - redirect page url">
            <TextInput
              name="redirectUrl"
              ref={register()}
            />
            {<FormError message={errors.redirectUrl?.message} />}
          </FormRow>
        </DetailsRow>
        <DetailsRow>
          <FormRow label="Handover screen - Title">
            <TextInput
              name="title"
              ref={register()}
            />
            {<FormError message={errors.title?.message} />}
          </FormRow>
        </DetailsRow>
        <DetailsRow>
          <FormRow label="Handover screen - Details">
            <InputTextArea
              name="insuranceDetails"
              ref={register()}
            />
            {<FormError message={errors.insuranceDetails?.message} />}
          </FormRow>
        </DetailsRow>
        <DetailsRow>
          <FormRow label="Handover screen - Small letter">
            <InputTextArea
              name="smallLetter"
              ref={register()}
            />
            {<FormError message={errors.smallLetter?.message} />}
          </FormRow>
        </DetailsRow>
        <DetailsRow>
          <FormRow label="Success screen - callback url">
            <TextInput
              name="callbackUrl"
              ref={register()}
            />
            {<FormError message={errors.successTitle?.message} />}
          </FormRow>
        </DetailsRow>
        <DetailsRow>
          <FormRow label="Success screen - Title">
            <TextInput
              name="successTitle"
              ref={register()}
            />
            {<FormError message={errors.successTitle?.message} />}
          </FormRow>
        </DetailsRow>
        <DetailsRow>
          <FormRow label="Success screen - Details">
            <InputTextArea
              name="successDetails"
              ref={register()}
            />
            {<FormError message={errors.successDetails?.message} />}
          </FormRow>
        </DetailsRow>
      </FormGroup>
      <Button
        type="submit"
        label="Submit"
        disabled={isLoading}
      />
    </form>
  );
};

const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 70rem;

  > div:nth-child(1n+2) {
    margin-left: 1rem;
  }
`;
