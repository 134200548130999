import styled, { css } from "styled-components";

interface TextInputProps {
  isInvalid?: boolean;
}

/**
 * Text input field
 */
export const TextInput = styled.input.attrs<TextInputProps>(({ isInvalid }) => ({ "aria-invalid": isInvalid || false }))<TextInputProps>`
  ${({ theme, isInvalid }) => css`
    font-size: ${theme.font.sizes.bodySmall};
    padding: 1.3rem;
    width: 100%;
    height: 4.8rem;
    border: 0.1rem solid ${!isInvalid ? theme.colors.Neutral800 : theme.actions.error};
    border-radius: ${theme.borderRadius.small};
    outline: none;
  `};
`;
