import React from "react";
import { toast } from "react-toastify";
import { Box } from "grommet";
import { PageHeader } from "src/components/shared/layout";
import { Layout } from "src/components/shared/layout/Layout";
import parse from "date-fns/parse";

import { PageProps } from "src/pages/Router";
import { useApiRequest, LocationTypeEnum } from "src/utils/api";
import { PublicUserForm, PublicUserFormInput } from "src/components/public-users/PublicUserForm";
import { LoadingSpinner } from "src/components/shared/loading-spinner";
import formatDate from "date-fns/format";

interface EditItemPageParams {
  userUuid: string;
}

export const EditPublicUserPage: React.FC<PageProps<{}, EditItemPageParams>> = props => {
  const userUuid = React.useMemo(() => props.match?.params.userUuid || "", [props.match]);
  const [updateUserResponse, updateUserRequest] = useApiRequest("USERS:updateUserInfo");
  const [getUserResponse, getUserRequest] = useApiRequest("USERS:getUserInfo");
  const [defaultValues, setDefaultValues] = React.useState<Partial<PublicUserFormInput>>();

  // On form submission
  const onSubmit = (user: Partial<PublicUserFormInput>) => {
    updateUserRequest({
      pathParams: { userUuid },
      data: { 
        ...user,
        emailAddress: user.email,
        // Convert the date back to yyyy-MM-dd.
        birthdate: user.birthdate ? formatDate(parse(
          user.birthdate, "dd/MM/yyyy", new Date()
        ), "yyyy-MM-dd") : user.birthdate,
        // Convert the location back to DTO format.
        locationDTO: {
          address1: user.locationAddress1 ? user.locationAddress1 : undefined,
          address2: user.locationAddress2 ? user.locationAddress2 : undefined,
          city: user.locationCity ? user.locationCity : undefined,
          postCode: user.locationPostcode ? user.locationPostcode : undefined,
          type: LocationTypeEnum.PRIMARY_ADDRESS
        }
      }
    });
  };

  React.useEffect(() => {
    if (updateUserResponse.data) {
      toast.success(updateUserResponse.data.message);
      props.history.push("/users/public");
    }

    if (updateUserResponse.errorMessage) {
      toast.error(updateUserResponse.errorMessage);
    }
  }, [
    updateUserResponse.data,
    updateUserResponse.errorMessage,
    props.history
  ]);

  // Get the details of the user to update.
  React.useEffect(() => {
    getUserRequest({ pathParams: { userUuid } });
  }, [getUserRequest, userUuid]);

  // Retrieved user details / set default values.
  React.useEffect(() => {
    if (getUserResponse.errorMessage) {
      toast.error(getUserResponse.errorMessage);
    }

    if (getUserResponse.data) {
      setDefaultValues({
        ...getUserResponse.data,
        // Pass emailAddress as email.
        email: getUserResponse.data.emailAddress,
        // Pass the date in format "dd/mm/YYYY"
        birthdate: getUserResponse.data.birthdate ? formatDate(new Date(getUserResponse.data.birthdate), "dd/MM/yyyy") : undefined,
        // Convert the location back.
        locationAddress1: getUserResponse.data.locationDTO?.address1 ? getUserResponse.data.locationDTO?.address1 : undefined,
        locationAddress2: getUserResponse.data.locationDTO?.address2 ? getUserResponse.data.locationDTO?.address2 : undefined,
        locationCity: getUserResponse.data.locationDTO?.city ? getUserResponse.data.locationDTO?.city : undefined,
        locationPostcode: getUserResponse.data.locationDTO?.postCode ? getUserResponse.data.locationDTO?.postCode : undefined
      });
    }
  }, [getUserResponse]);

  return (
    <Layout>
      <PageHeader title="Edit User" backLink={() => props.history.goBack()} />
      <Box>
        {defaultValues && !getUserResponse.loading ? (
          <PublicUserForm
            type="update"
            isLoading={updateUserResponse.loading}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
          />
        ) : <LoadingSpinner />}
      </Box>
    </Layout>
  );
};
