import React from "react";
import * as datefns from "date-fns";
import { Table } from "src/components/shared/table";
import {
  Column, Filters, Row, SortingRule
} from "react-table";
import { useApiRequest } from "src/utils/api";
import { History } from "history";

import { Card } from "src/components/shared/layout";
import { toast } from "react-toastify";
import {
  ItemDTO, ItemDTOStatusEnum, OrderByObject
} from "src/utils/api";
import {
  SelectItemCategoryColumnFilter, 
  SelectItemStatusColumnFilter
} from "src/components/items";

import { mapItemStatus } from "src/utils/item";

type ItemsTable = Pick<ItemDTO, "category" | "brand" | "model" | "color" | "dateFound" | "status" | "id">;

const columns: Column<ItemsTable>[] = [
  {
    Header: "Category",
    accessor: "category",
    Filter: SelectItemCategoryColumnFilter,
    Cell: ({ value }) => value.toLowerCase().charAt(0).toUpperCase() + value.toLowerCase().slice(1)
  },
  {
    Header: "Brand",
    accessor: "brand"
  },
  {
    Header: "Model",
    accessor: "model"
  },
  {
    Header: "Colour",
    accessor: "color"
  },
  {
    Header: "Registered On",
    accessor: "dateFound",
    disableFilters: true,
    Cell: ({ value }) => value ? datefns.format(value, "dd/MM/yyyy HH:mm") : ""
  },
  {
    accessor: "status",
    Header: "Status",
    Filter: SelectItemStatusColumnFilter,
    Cell: ({ value }) => mapItemStatus(value as ItemDTOStatusEnum)
  }
];

const hiddenColumns = ["id"];

const queryFiltersToString = (filters: Filters<ItemsTable>): string => {
  return filters.filter(filter => !!filter.value).map(filter => {
    let value = filter.value;

    // User LIKE operator.
    if (filter.id === "brand" || filter.id === "model" || filter.id === "color") {
      value = `~${value}`;
    } else {
      value = `:${value}`;
    }

    return `${filter.id}${value}`
    ;
  }).join(",");
};

interface PublicUserItemsTableProps {
  userUuid: string;
  history: History<History.PoorMansUnknown>;
}

export const PublicUserItemsTable: React.FC<PublicUserItemsTableProps> = props => {
  const [data, setData] = React.useState<ItemDTO[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [itemsResponse, itemsRequest] = useApiRequest("ITEMS:getItemsByUser");
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(({
    pageSize, pageIndex, tableFilters, sortBy
  }: {
    pageSize: number;
    pageIndex: number;
    tableFilters?: Filters<ItemsTable>;
    sortBy?: Array<SortingRule<ItemsTable>>;
  }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Setup order with default, if included:
    let order: OrderByObject<ItemDTO> = { category: "ASC" };

    // Override with sortBy from the table, if included:
    if (sortBy && sortBy[0]) {
      order = { [sortBy[0].id as keyof ItemsTable]: sortBy[0].desc ? "DESC" : "ASC" };
    }

    if (fetchId === fetchIdRef.current) {
      const filters: Partial<ItemsTable> = {};

      tableFilters?.map(filter => {
        const id = filter.id as keyof ItemsTable;

        filters[id] = filter.value;
      });
      // Set the loading state
      setLoading(true);

      itemsRequest({
        pathParams: { userUuid: props.userUuid },
        params: {
          query: tableFilters && tableFilters.length ? queryFiltersToString(tableFilters) : "",
          page: pageIndex + 1,
          pageSize,
          sortBy: Object.keys(order).map(sortField => `${sortField}:${order[sortField as keyof ItemDTO]}`).join(",")
        }
      });
    }
  }, [itemsRequest, props.userUuid]);

  React.useEffect(() => {
    if (itemsResponse.data && itemsResponse.data.items) {
      setData(itemsResponse.data.items);
      setPageCount(Math.ceil(itemsResponse.data.total / itemsResponse.data.pageSize));
      setLoading(false);
    }

    if (itemsResponse.errorMessage) {
      toast.error(itemsResponse.errorMessage);
    }
  }, [itemsResponse]);

  const handleOnClick = React.useCallback((row: Row<ItemsTable>) => {
    props.history.push(`/items/${row.original.id}`);
  }, [props.history]);

  return (
    <Card>
      <Table<ItemsTable>
        name={"itemsTable"}
        title={`${itemsResponse.data?.total || 0} Items`}
        columns={columns}
        data={data}
        loading={loading}
        onClick={handleOnClick}
        fetchData={fetchData}
        defaultHiddenColumns={hiddenColumns}
        pageCount={pageCount}
        usePagination={true}
        useFilters={true}
        useHideColumns={true}
        useResizeColumns
        useSortBy
        featuredFilter="model"
      />
    </Card>
  );
};
