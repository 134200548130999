import {
  BasePaginatedQuery,
  OrderBy,
  PickParams,
  ResponseId,
  StringResponse
} from "../api";
import { ApiRouteDefinition } from "../useApiRequest";
import { FileResource } from "./files.api";
import { ItemCategoryEnum } from "../shared-types";

export const ItemRoutes = {
  /** Allows a business user to view the items of all users belonging to their business */
  "ITEMS:getItemsByBusiness": {
    path: "/:userUuid/business/item",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PaginatedCollectionResponseItemDTO,
    null,
    PickParams<"userUuid">,
    BusinessItemsQuery
  >,

  /** Get all items. Filters can be provided to return only items matching specific criteria. */
  "ITEMS:getAllItemsByFilter": {
    path: "/items",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PaginatedCollectionResponseItemDTO,
    null,
    null,
    AllItemsByFilterQuery
  >,

  /** Delete list of items */
  "ITEMS:deleteItems": {
    path: "/items",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<StringResponse, null, null, DeleteItemsQuery>,

  /** Get an item */
  "ITEMS:getItem": {
    path: "/items/:itemUuid",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<ItemDTO, null, PickParams<"itemUuid">, null>,

  /** Update Item */
  "ITEMS:updateItem": {
    path: "/items/:itemUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<ItemDTO, ItemDTO, PickParams<"itemUuid">>,

  /** Delete Item */
  "ITEMS:deleteItem": {
    path: "/items/:itemUuid",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<StringResponse, null, PickParams<"itemUuid">>,

  /** Save item image*/
  "ITEMS:saveItemImage": {
    path: "/items/:itemUuid/images",
    authenticate: true,
    method: "POST",
    headers: { ContentType: "multipart/form-data" }
  } as ApiRouteDefinition<
    ItemDTO,
    FormData,
    PickParams<"itemUuid">
  >,

  "ITEMS:deleteItemImage": {
    path: "/item/:itemUuid/images/:fileUuid",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<
    StringResponse,
    null,
    PickParams<"itemUuid" | "fileUuid">
  >,

  /** Get an item */
  "ITEMS:getAllItems": {
    path: "/items/all",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<AllItemResponse, null, null, AllItemsQuery>,

  /** Register new found item : PENDING REGISTER USER */
  "ITEMS:registerFoundItemPendingUser": {
    path: "/items/found",
    authenticate: true,
    method: "POST",
    headers: { ContentType: "multipart/form-data" }
  } as ApiRouteDefinition<FileResource, FormData>,

  // TODO: Check response type!
  /** Edit existing found item */
  "ITEMS:updateFoundItem": {
    path: "/items/found/:itemUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    FoundItemUpdateRequest,
    PickParams<"itemUuid">
  >,

  /** Mark item as returned */
  "ITEMS:markItemAsReturned": {
    path: "/items/found/:itemUuid/returned",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    null,
    PickParams<"itemUuid">
  >,

  /** Register new found item : EXISTING USER */
  "ITEMS:registerFoundItem": {
    path: "/items/found/:userUuid",
    authenticate: true,
    method: "POST",
    headers: { ContentType: "multipart/form-data" }
  } as ApiRouteDefinition<
    FoundItemResponse,
    FormData,
    PickParams<"userUuid">
  >,

  /** Register new lost item:PENDING REGISTER USER */
  "ITEMS:registerLostItemPendingUser": {
    path: "/items/lost/:userUuid",
    authenticate: true,
    method: "POST",
    headers: { ContentType: "multipart/form-data" }
  } as ApiRouteDefinition<
    ResponseId,
    LostItemRequest,
    PickParams<"userUuid">
  >,

  /** Edit existing lost item */
  "ITEMS:updateLostItem": {
    path: "/items/lost/:itemUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    LostItemUpdateRequest,
    PickParams<"itemUuid">
  >,

  /** Mark item as found */
  "ITEMS:markAsFound": {
    path: "/items/lost/:itemUuid/found",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    null,
    PickParams<"itemUuid">
  >,

  /** Register new lost item:EXISTING USER */
  "ITEMS:registerLostItem": {
    path: "/items/lost/:userUuid",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
    ResponseId,
    LostItemRequest,
    PickParams<"userUuid">
  >,

  /** Register new preregistered item : PENDING USER */
  "ITEMS:preregisterItemPendingUser": {
    path: "/items/preregistered",
    authenticate: true,
    method: "POST",
    headers: { ContentType: "multipart/form-data" }
  } as ApiRouteDefinition<FoundItemResponse, FormData>,

  /** Edit existing preregistered item */
  "ITEMS:updatePreregisteredItem": {
    path: "/items/preregistered/:itemUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    FoundItemUpdateRequest,
    PickParams<"itemUuid">
  >,

  /** Mark item as lost */
  "ITEMS:markAsLost": {
    path: "/items/preregistered/:itemUuid/lost",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<
    StringResponse,
    MarkLostItemRequest,
    PickParams<"itemUuid">
  >,

  /** Register new preregistered item : EXISTING USER */
  "ITEMS:preregisterItem": {
    path: "/items/preregistered/:userUuid",
    authenticate: true,
    method: "POST",
    headers: { ContentType: "multipart/form-data" }
  } as ApiRouteDefinition<
    FoundItemResponse,
    FormData,
    PickParams<"userUuid">
  >,

  /** Update status for list of items */
  "ITEMS:updateItemStatus": {
    path: "/items/status",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<StringResponse, ItemStatusUpdate>,

  /** Fetch all user items */
  "ITEMS:getItemsByUser": {
    path: "/users/:userUuid/items/all",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PaginatedCollectionResponseItemDTO,
    null,
    PickParams<"userUuid">,
    UserItemsQuery
  >,

  /** Fetch user preregistered items */
  "ITEMS:getUserPreregisteredItems": {
    path: "/users/preregistered/:userUuid/items",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    PreRegisteredItemResponse,
    null,
    PickParams<"userUuid">,
    AllItemsQuery
  >,

  /** Fetch user lost/found items */
  "ITEMS:getUserItemsLostFound": {
    path: "/users/users/:userUuid/items",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<AllItemResponse, null, PickParams<"userUuid">>,

  /** Fetch user returned items */
  "ITEMS:getReturnedItems": {
    path: "/users/users/preregistered/:userUuid/returned",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<AllItemResponse, null, PickParams<"userUuid">>,

  /** Fetch user returned items */
  "ITEMS:adminPregisterItem": {
    path: "/items/admin/preregistered/:userUuid",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<{id: string}, CreateItemAsAdminInput, PickParams<"userUuid">>,

  /** Create item insurance */
  "ITEMS:adminUpdateItem": {
    path: "/items/admin/:itemUuid",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<ItemInsuranceDTO, AdminUpdateItemRequest, PickParams<"itemUuid">>,

  /** Create item insurance */
  "ITEMS:createItemInsurance": {
    path: "/items/:itemUuid/insurance",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<ItemInsuranceDTO, CreateItemInsuranceRequest, PickParams<"itemUuid">>,

  /** Create item insurance */
  "ITEMS:updateItemInsurance": {
    path: "/items/:itemUuid/insurance",
    authenticate: true,
    method: "PUT"
  } as ApiRouteDefinition<ItemInsuranceDTO, EditItemInsuranceRequest, PickParams<"itemUuid">>
};

export interface DeleteItemsQuery {
  ids: string[];
}

export interface UserItemsQuery extends BasePaginatedQuery {
  // Return only items matching this filter. The filter supports the operators ':’, '>’, or ‘<’ for 'equals’, ‘greater than’ or 'less than’. A filter can contain more than one field. For example ‘brand:MyBrand,range:ExampleRange’ finds all items branded MyBrand with range ExampleRange.
  query?: string;
  sortBy?: OrderBy;
}

export interface BusinessItemsQuery extends BasePaginatedQuery {
  query?: string;
  search?: string;
}

export interface AllItemsByFilterQuery extends BasePaginatedQuery {
  query?: string;
  userQuery?: string;
  sortBy?: OrderBy;
}

export interface AllItemsQuery extends BasePaginatedQuery {
  page: number;
  pageSize: number;
  defaultCategory?: ItemCategoryEnum;
}

export interface PaginatedCollectionResponseItemDTO {
  items: Array<ItemDTO>;
  itemsCount: number;
  page: number;
  pageSize: number;
  total: number;
}

export interface PreRegisteredItemResponse {
  preRegisteredItems?: Array<ItemDTO>;
}
export interface AllItemResponse {
  foundItemsResponse?: Array<ItemDTO>;
  lostItemResponse?: Array<ItemDTO>;
}
export interface LostItemUpdateRequest {
  approximate?: number;
  brand?: string;
  category?: ItemCategoryEnum;
  color?: string;
  dateEnd?: number;
  dateStart?: number;
  description?: string;
  insured?: boolean;
  latitude?: number;
  longitude?: number;
  mail?: string;
  model?: string;
  name?: string;
  range?: string;
  receipt?: boolean;
  value?: number;
}

export interface LostItemRequest {
  approximate?: number;
  brand?: string;
  category?: ItemCategoryEnum;
  color?: string;
  date?: number;
  dateEnd?: number;
  dateStart?: number;
  description?: string;
  insured?: boolean;
  latitude?: number;
  longitude?: number;
  mail?: string;
  model?: string;
  name?: string;
  receipt?: boolean;
  type?: string;
  value?: number;
}

export interface FoundItemResponse {
  brand?: string;
  category?: ItemCategoryEnum;
  date?: number;
  description?: string;
  id?: string;
  images?: Array<string>;
  latitude?: number;
  longitude?: number;
  model?: string;
  name?: string;
  receipts?: Array<string>;
  type?: string;
}

export interface FoundItemUpdateRequest {
  approximate?: number;
  brand?: string;
  category?: ItemCategoryEnum;
  color?: string;
  date?: number;
  description?: string;
  latitude?: number;
  longitude?: number;
  mail?: string;
  model?: string;
  name?: string;
  range?: string;
  value?: number;
}

export enum ItemAdminFlagsEnum {
  REPORTED = "REPORTED",
  BANNED = "BANNED",
  OK = "OK",
  FLAGGED = "FLAGGED",
  ABUSE = "ABUSE",
  NOTACTIVATED = "NOT_ACTIVATED",
  INVITED = "INVITED"
}

export enum ItemOriginEnum {
  POS = "POS",
  SUPPORT = "SUPPORT",
  DEFAULT = "DEFAULT"
}

export enum ItemInsuranceProviderEnum {
  LAKA = "LAKA",
  GADGET_COVER = "GADGET_COVER",
  OTHER = "OTHER"
}

export interface CreateItemAsAdminInput {
  category: ItemCategoryEnum;
  brand?: string;
  model?: string;
  color?: string;
  origin: ItemOriginEnum;
  originInfo?: string;
  description?: string;
  posBusinessPartnerUUID?: string;
}

export interface CreateItemAsAdminInput {
  category: ItemCategoryEnum;
  brand?: string;
  model?: string;
  color?: string;
  origin: ItemOriginEnum;
  originInfo?: string;
  description?: string;
  posBusinessPartnerUUID?: string;
  userUUID: string;
}
export interface AdminUpdateItemRequest {
  category?: ItemCategoryEnum;
  brand?: string;
  model?: string;
  color?: string;
  origin: ItemOriginEnum;
  originInfo?: string;
  description?: string;
  posBusinessPartnerUUID?: string;
  userUUID: string;
}

/**
 *
 * @export
 * @interface ItemDTO
 */
export interface ItemDTO {
  approximate?: number;
  brand: string;
  category: ItemCategoryEnum;
  color: string;
  dateCreated: Date;
  dateFound?: Date;
  dateLostEnd?: Date;
  dateLostStart?: Date;
  description?: string;
  id?: string;
  images: Array<string>;
  insured?: boolean;
  latitude?: number;
  longitude?: number;
  mail?: string;
  matchPending?: boolean;
  model: string;
  name: string;
  receipt: boolean;
  receipts?: Array<string>;
  status: ItemDTOStatusEnum;
  type?: string;
  userUUID?: string;
  value?: number;
  origin?: ItemOriginEnum;
  originInfo?: string;
  posBusinessPartnerUUID: string;
  currentInsurance: ItemInsuranceDTO | null;
}
export type CreateItemInsuranceRequest = Pick<
  ItemInsuranceDTO,
  "active" |
  "dateInsuranceStarted" |
  "providerId" |
  "quoteId" |
  "policyNumber" |
  "type"
>;

export type EditItemInsuranceRequest = Partial<ItemInsuranceDTO>;
export interface ItemInsuranceDTO {
  ID?: string;
  createdBy?: string;
  dateCreated?: Date;
  dateDeleted?: Date;
  dateUpdated?: Date;
  dateInsuranceStarted?: Date;
  deletedBy?: string;
  active: boolean;
  providerId: string;
  quoteId?: string | null;
  policyNumber?: string | null;
  type: ItemInsuranceType;
  updatedBy?: string;
}

export enum ItemInsuranceType {
  QUOTE = "QUOTE",
  POLICY = "POLICY"
}

export interface ItemInsuranceData {
  provider: ItemInsuranceProviderEnum;
  policyNumber: string | null;
  quoteId: string | null;
  createdDate: Date | string | null;
}

export enum ItemDTOStatusEnum {
  PREREGISTERED = 0,
  LOST = 1,
  FOUND = 2,
  RETURNED = 3,
  PENDING_USER_PREREGISTERED_ITEM = 10,
  PENDING_USER_LOST_ITEM = 11,
  PENDING_USER_FOUND_ITEM = 12
}

export interface MarkLostItemRequest {
  dateStart?: number;
  dateEnd?: number;
  latitude?: number;
  longitude?: number;
}

/**
 *
 * @export
 * @interface ItemStatusUpdate
 */
export interface ItemStatusUpdate {
  lostItemDetails?: MarkLostItemRequest;
  status?: number;
  uuid?: string;
}
/**
 *
 * @export
 * @interface ItemStatusUpdateRequest
 */
export interface ItemStatusUpdateRequest {
  statusUpdates?: Array<ItemStatusUpdate>;
}