import {
  StorageKey,
  useAuthenticationContext
} from "../Authentication";

/** Sign out  */
const useSignOut = (onLogout?: () => void) => {
  const { setAuthTokenState } = useAuthenticationContext();

  return async () => {
    try {
      // Clear the token cache
      await window.localStorage.removeItem(StorageKey);
      // Set to null so the app know we logged out
      setAuthTokenState(null);

      if (onLogout) {
        onLogout();
      }

      return;
    } catch (e) {
      throw new Error("Unknown error occurred");
    }
  };
};

export default useSignOut;
