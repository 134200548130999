import React from "react";

import { Table } from "src/components/shared/table";
import {
  Column, Filters, Row, SortingRule
} from "react-table";
import {
  useApiRequest, UserDTO, OrderByObject 
} from "src/utils/api";

import { TextInput } from "src/components/forms/TextInput";
import { Button } from "src/components/shared/button";
import { Layout } from "src/components/shared/layout/Layout";
import { PageHeader, Card } from "src/components/shared/layout";
import { PageProps } from "src/pages/Router";
import { toast } from "react-toastify";
import * as datefns from "date-fns";

type PublicUsersTable = Pick<UserDTO, "firstName" | "lastName" | "dateCreated" | "dateLastActivity" | "itemsRegistered" | "itemsReturned" | "id" | "itemsLost" | "emailAddress">;

const queryFiltersToString = (filters: Filters<PublicUsersTable>): string => {
  return filters.filter(filter => !!filter.value).map(filter => {
    let value = filter.value;

    // User LIKE operator.
    if (filter.id === "firstName" || filter.id === "lastName" || filter.id === "emailAddress") {
      value = `~${value}`;
    } else if (filter.id === "itemsRegistered" || filter.id === "itemsReturned" || filter.id === "itemsLost") {
      if (value.charAt(0) === "<" || value.charAt(0) === ">") {
        value = `${value}`;
      } else if (value.charAt(0) === "=") {
        value = `${value.substring(1)}`;
      } else {
        value = `:${value}`;
      }
    } else {
      value = `:${value}`;
    }

    return `${filter.id}${value}`
    ;
  }).join(",");
};

const columns: Column<PublicUsersTable>[] = [
  {
    Header: "First name",
    accessor: "firstName"
  },
  {
    Header: "Last name",
    accessor: "lastName"
  },
  {
    Header: "Email",
    accessor: "emailAddress"
  },
  {
    Header: "Joined",
    accessor: "dateCreated",
    disableFilters: true,
    Cell: ({ value }) => value ? datefns.format(value, "dd/MM/yyyy HH:mm") : ""
  },
  {
    Header: "Last Active",
    accessor: "dateLastActivity",
    disableFilters: true,
    Cell: ({ value }) => value ? datefns.format(value, "dd/MM/yyyy HH:mm") : ""
  },
  {
    Header: "Registered Items",
    accessor: "itemsRegistered",
    disableFilters: true,
    disableSortBy: true,
    // eslint-disable-next-line react/display-name
    Filter: ({ column: { filterValue, setFilter } }) => (
      <TextInput
        placeholder="Use >,< or =. Examples: '=4', '<1', >10"
        value={filterValue}
        onChange={event => setFilter(event.target.value)}
      />
    )   
  },
  {
    Header: "Lost Items",
    accessor: "itemsLost",
    disableFilters: true,
    disableSortBy: true,
    // eslint-disable-next-line react/display-name
    Filter: ({ column: { filterValue, setFilter } }) => (
      <TextInput
        placeholder="Use >,< or =. Examples: '=4', '<1', >10"
        value={filterValue}
        onChange={event => setFilter(event.target.value)}
      />
    )
  },
  {
    Header: "Recovered Items",
    accessor: "itemsReturned",
    disableFilters: true,
    disableSortBy: true,
    // eslint-disable-next-line react/display-name
    Filter: ({ column: { filterValue, setFilter } }) => (
      <TextInput
        placeholder="Use >,< or =. Examples: '=4', '<1', >10"
        value={filterValue}
        onChange={event => setFilter(event.target.value)}
      />
    )
  }
];

const hiddenColumns = ["id"];

const PublicUsersPage: React.FC<PageProps<{}>> = props => {
  const [data, setData] = React.useState<UserDTO[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [usersResponse, partnersRequest] = useApiRequest("USERS:getAllPublicUsers");
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(({
    pageSize, pageIndex, tableFilters, sortBy
  }: {
    pageSize: number;
    pageIndex: number;
    tableFilters?: Filters<PublicUsersTable>;
    sortBy?: Array<SortingRule<PublicUsersTable>>;
  }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const filters: Partial<PublicUsersTable> = {};
      // Setup order with default, if included:
      let order: OrderByObject<UserDTO> = { dateCreated: "DESC" };

      // Override with sortBy from the table, if included:
      if (sortBy && sortBy[0]) {
        order = { [sortBy[0].id as keyof PublicUsersTable]: sortBy[0].desc ? "DESC" : "ASC" };
      }

      tableFilters?.map(filter => {
        const id = filter.id as keyof PublicUsersTable;

        filters[id] = filter.value;
      });
      // Set the loading state
      setLoading(true);

      partnersRequest({
        params: {
          page: pageIndex + 1,
          pageSize,
          query: tableFilters && tableFilters.length ? queryFiltersToString(tableFilters) : "",
          sortBy: Object.keys(order).map(sortField => `${sortField}:${order[sortField as keyof UserDTO]}`).join(",")
        }
      });
    }
  }, [partnersRequest]);

  React.useEffect(() => {
    if (usersResponse.data && usersResponse.data.items) {
      setData(usersResponse.data.items);
      setPageCount(Math.ceil(usersResponse.data.total / usersResponse.data.pageSize));
      setLoading(false);
    }

    if (usersResponse.errorMessage) {
      toast.error(usersResponse.errorMessage);
    }
  }, [usersResponse]);

  const handleOnClick = React.useCallback((row: Row<PublicUsersTable>) => {
    props.history.push(`/users/public/${row.original.id}`);
  }, [props.history]);

  return (
    <Layout>
      <PageHeader title="All public users" callToAction={<Button label="New" onClick={() => props.history.push("/users/public/new")} />} />
      <Card>
        <Table<PublicUsersTable>
          name={"public users table"}
          title={`${usersResponse.data?.total || 0} Public Users`}
          columns={columns}
          data={data}
          loading={loading}
          fetchData={fetchData}
          onClick={handleOnClick}
          defaultHiddenColumns={hiddenColumns}
          pageCount={pageCount}
          usePagination={true}
          useFilters={true}
          featuredFilter="emailAddress"
          useHideColumns={true}
          useResizeColumns
          useSortBy
        />
      </Card>
    </Layout>
  );
};

export default PublicUsersPage;
