import React from "react";
import styled from "styled-components";

import { InfoLayoutColumn } from "./InfoLayoutColumn";

/**
 * Info layout column left
 *
 * Usage: InfoLayoutWrapper > InfoLayoutColumnLeft
 */
export const InfoLayoutColumnLeft: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { children, ...rest } = props;

  return (
    <InfoLayoutColumnLeftStyled {...rest}>
      {children}
    </InfoLayoutColumnLeftStyled>
  );
};

const InfoLayoutColumnLeftStyled = styled(InfoLayoutColumn)`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    padding-right: 1%;
    width: 50%;
  }

  @media (min-width: 1380px) {
    width: 60%;
  }
`;
